[negative-input-marker="true"]::before {
	content: '-';
	position: absolute;
	top: 0.47em;
	left: 0.9rem;
	z-index: 5;
}
[negative-input-marker="true"] > input {
	padding-left: 1.4rem !important;
}

[negative-input-marker="true"] > input-group-currency input {
	padding-left: 1.4rem !important;
}

[negative-p-marker="true"]::before {
	content: '-';
	position: absolute;
	top: 0.47em;
	z-index: 5;
}

[negative-p-marker="true"] > p {
	margin-left: 0.6rem !important;
}