/* ========================================================================== */
/* IMPORT PAGES */
/* Import pages specific styles  */
/* ========================================================================== */

.import-creation-page
{
	.page-header h1
	{
		justify-content: space-between;
		width: 100%;
	}

	.tab-pane
	{
		min-height: 580px;
	}
}

/* Import upload
 * ========================================================================== */
.import-create-upload
{
	.files-list
	{
		&-container
		{
			position: relative;
			background: $import-files-container;
		}

		&-empty
		{
			padding: $spacing-xl;
			margin: 0 0 $spacing;
		}

		&-selected
		{
			@include position-absolute(1px, 1px, 1px, 1px); // 1px to show the border through
			background: rgba($import-files-container-notempty, .8);
			padding: $spacing;
			overflow: auto;
			margin: 0;
		}
	}
}

/* Import columns' mapping
 * ========================================================================== */
.import-create-mapping-columns
{

}

.import-required-columns
{
	margin: 0 0 $spacing-md;

	&-chip
	{
		margin: 5px;
		background: $chip-bg-color;
		border: $chip-border;
		border-radius: $chip-border-radius;
		color: $chip-color;
		font-size: $chip-font-size;
		padding: 3px $chip-padding-x;

		&.valid
		{
			background: $chip-active-bg;
			border-color: $chip-active-border-color;
			color: $chip-active-color;
		}

		&s
		{
			display: flex;
			flex-wrap: wrap;
			margin: 0 -5px;
		}
	}
}

/* Import values' mapping
 * ========================================================================== */
.import-create-mapping-values
{
	margin: $spacing-md 0 0;

	.form-group
	{
		margin-top: $spacing-xs;
	}

	.form-control
	{
		border-width: 2px;
		text-overflow: ellipsis;
		overflow: hidden;
	}
}

/* Import synthesis
 * ========================================================================== */
.import-create-synthesis
{
	&-card
	{
		@include card-base($border: null, $background: $gray-100, $padding: $spacing-md);

		ul
		{
			margin: 0;
		}

		li
		{
			+ li
			{
				margin-top: $spacing-xs;
			}
		}

		.sep
		{
			margin-top: $spacing-md;
		}

		.label
		{
			color: $gray-700;
			display: block;
			margin: 0 0 5px;

			&-warning
			{
				&:before
				{
					@extend %fa-icon;
					@extend .fas;
					content: fa-content($fa-var-exclamation-triangle);
					color: $danger;
					margin-right: 5px;
				}
			}
		}

		.value
		{
			font-size: $font-size-md;
		}

		@include media-breakpoint-up(lg)
		{
			li
			{
				display: flex;
			}

			.label
			{
				flex: 1;
				padding-right: $spacing;
				margin: 0;
			}

			.value
			{
				flex: 3;
			}
		}
	}
}

/* Import validation
 * ========================================================================== */
.import-validation-processing
{
	.completed-accounting-list
	{
		margin-top: $spacing-md;
	}
}

/* Import history
 * ========================================================================== */
.import-history
{
	.item-counter
	{
		padding: $spacing;

		label
		{
			margin-left: $spacing-xs;
		}
	}
}


