/* ========================================================================== */
/* DUNNING */
/* Dunning elements vars */
/* ========================================================================== */

/* Dunning Scenario timeline
 * ========================================================================== */
$dunning-scenario-timeline-bar-bg: 						#f1f1f1 !default;
$dunning-scenario-timeline-bar-h: 						5px !default;
$dunning-scenario-timeline-bar-offset: 					10px !default;
$dunning-scenario-timeline-filling-bar-bg: 				$primary !default;
$dunning-scenario-timeline-filling-bar-transition: 		transform $transition-speed $transition-fn !default;

$dunning-scenario-timeline-event-w: 					150px !default;
$dunning-scenario-timeline-event-color: 				$gray-450 !default;
$dunning-scenario-timeline-event-caption-size: 			26px !default;
$dunning-scenario-timeline-event-caption-bg: 			$primary !default;
$dunning-scenario-timeline-event-caption-color: 		$secondary-light !default;
$dunning-scenario-timeline-event-caption-border: 		0 0 0 3px $white !default;
$dunning-scenario-timeline-event-active-color: 			$body-color !default;
$dunning-scenario-timeline-event-active-caption-bg: 	$primary !default;
$dunning-scenario-timeline-event-active-caption-color: 	$white !default;

$dunning-scenario-timeline-action-btn-size: 			30px !default;
$dunning-scenario-timeline-slider-margin: 				2px $spacing-md 0 !default;
$dunning-scenario-timeline-detail-margin: 				$spacing-md ($dunning-scenario-timeline-action-btn-size + nth($dunning-scenario-timeline-slider-margin, 2)) $spacing !default;


/* Super-Debtor dunning timeline
 * ========================================================================== */
$super-debtor-dunning-timeline-line-start:				25px !default;
$super-debtor-dunning-timeline-line-stop:				27px !default;
$super-debtor-dunning-timeline-line-color:				#e0e4ee !default;

$super-debtor-dunning-timeline-item-spacing:				15px !default;
$super-debtor-dunning-timeline-item-marker-size:			16px !default;
$super-debtor-dunning-timeline-item-marker-border:		3px solid $gray-700 !default;
$super-debtor-dunning-timeline-item-marker-bg:			$white !default;

$super-debtor-dunning-timeline-item-header-padding:		0 0 $super-debtor-dunning-timeline-item-spacing ($super-debtor-dunning-timeline-line-start * 2) !default;
$super-debtor-dunning-timeline-item-status-font-size:	$font-size-lg !default;
$super-debtor-dunning-timeline-item-infos-font-size:		px-to-rem(11px) !default;

$super-debtor-dunning-timeline-item-type-h:				2 * $headings-line-height * $font-size-base !default;

$super-debtor-dunning-timeline-item-content-bg:			$white !default;
$super-debtor-dunning-timeline-item-content-shodow:		0 1px 2px 0 rgba($black, 0.06) !default;	
$super-debtor-dunning-timeline-item-content-border-w:	$border-width !default;
$super-debtor-dunning-timeline-item-content-border:		$super-debtor-dunning-timeline-item-content-border-w solid $super-debtor-dunning-timeline-line-color !default;		
$super-debtor-dunning-timeline-item-content-padding:		$spacing !default;		
$super-debtor-dunning-timeline-item-meta-width:			190px !default;		

$super-debtor-dunning-timeline-action-todo-color:		$secondary !default;	
$super-debtor-dunning-timeline-action-done-color:		$green !default;

$super-debtor-dunning-timeline-action-todo-meta-bg:		#e0efff !default;		
$super-debtor-dunning-timeline-action-todo-meta-border:	#beddff !default;		
$super-debtor-dunning-timeline-action-done-meta-bg:		$gray-100 !default;		
$super-debtor-dunning-timeline-action-done-meta-hover-bg: 	$gray-200 !default;		
						
$super-debtor-dunning-timeline-event-content-bg:			$gray-100 !default;						