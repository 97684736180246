@keyframes enter {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes exit {
  0% {
    opacity: 1;
    filter: blur(0px);
  }

  100% {
    opacity: 0;
    filter: blur(5px);
  }
}

#main {
  view-transition-name: main;
}

::view-transition-old(main) {
  animation: exit .4s ease-in-out 0s 1 normal forwards;
}

::view-transition-new(main) {
  animation: enter .6s ease-in-out 0s 1 normal forwards;
}
