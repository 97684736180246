.menu-bookmarks {
	@extend .menu-links;
	display: flex !important;
	flex-direction: column-reverse;
	margin: 0;
	height: 40%;
}

.menu-bookmark {
	font-size: smaller;
}
 
.menu-bookmark-delete {
	@extend .btn-reset;
	color: $secondary-light;
}

page-header bookmarker { visibility: hidden; }
page-header:hover bookmarker,
page-header bookmarker.marked { visibility: visible }
