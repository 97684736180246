/* ========================================================================== */
/* TRANSFERS VARS */
/* Transfer vars */
/* ========================================================================== */


/* Transfer control quote */
/* ========================================================================== */
$transfer-quote-margin:						30px 0 30px !default;
$transfer-quote-padding:					$spacing !default;
$transfer-quote-label-width:				250px !default;
$transfer-validation-label-width:			300px !default;
$transfer-survey-label-width:				300px !default;
