/* ========================================================================== */
/* MAIN CONTAINER */
/* Layout's main container elements */
/* ========================================================================== */

/* Container
 * ========================================================================== */
#main
{
	.authenticated-layout &
	{
		position: relative;
		padding: $main-padding;
		background: $main-bg;
		border-top: $main-border;
	}

	&.overlay
	{
		overflow: hidden;

		&:after
		{
			@include position(fixed, 0, 0, 0, 0);
			content: '';
			z-index: 2;
			height: 100%;
			background: $main-notification-bg;
			opacity: $main-notification-opacity;
		}
	}
}



/* Breadcrumb
 * ========================================================================== */
#breadcrumb
{
	color: $breadcrumb-color;
	font-size: $breadcrumb-font-size;

	a
	{
		color: inherit;
	}

	.kyc-control-page &,
	.transfer-control-page &
	{
		@include media-breakpoint-up(xl)
		{
			width: $breadcrumb-control-page-width;
		}	
	}
}

.breadcrumb
{
	&-item
	{
		display: inline;
	}
}

/* Page header
 * ========================================================================== */
.page-header
{
	@include flex-spacebetween(flex-start);

	h1
	{
		display: flex;
		flex-wrap: wrap;
		align-items: baseline;
	}

	&-dropdown
	{
		width: $page-header-dropdown-w;
	}

	[page-header-area=subtitle]
	{
		vertical-align: bottom;
	}
	
	[page-header-area=button]
	{
		flex-shrink: 0;
		flex-grow: 0;
	}
}


/* Page anchors
 * ========================================================================== */
.page-anchors
{
	$context: &;

	@include position-fixed(null, 0);
	@include pos-center-y;
	z-index: $zindex-fixed;
	
	&-item
	{
		&:first-child #{$context}-btn
		{
			border-top-left-radius: $border-radius;	
			border-top: 0;
		}

		&:last-child #{$context}-btn
		{
			border-bottom-left-radius: $border-radius;	
		}
	}
	
	&-btn
	{
		@include rectangle($page-anchors-btn-size);
		@include flex-center;
		background: $page-anchors-bg;
		color: $page-anchors-color;
		border-top: 1px solid $page-anchors-border;
		
		&:hover
		{
			background: $page-anchors-hover-bg;
			color: $page-anchors-color;
			text-decoration: none;
		}

		i
		{
			font-size: $page-anchors-icon-size;
		}
	}
}

/* Columns
 * ========================================================================== */
.col
{
	@include media-breakpoint-up(lg)
	{
		&-bordered
		{
			@include border-right($border-width, $gray-350);
			padding-right: $spacing;
			
			+ [class^=col-],
			+ [class*=col-]
			{
				padding-left: $spacing;	
			}
		}
	}
	
}