@import "placeholder-loading/src/scss/placeholder-loading";

body .ph-item {
    background-color: $content-loader-bg;

    .ph-row div {
        background-color: $content-loader-el-bg;
    }
}

.content-block .ph-item {
    border: none;
    margin-bottom: 5px;
    margin-top: 5em;
}

.content-block .ph-item .block-loader .loader {
    background: rgba(255, 255, 255, 0.95);
    margin-top: 4.5em;
}

.ph-item div.ph-picture-tall {
    height: 150px;
}

.ph-avatar-medium {
    width: 75%;
    margin: 0 auto 30px auto;
}

// avatar display badly on small content
@include media-breakpoint-up(md) {
    .ph-avatar-medium {
        width: 25%;
    }
}

.block-loader .loader.ph-item > .ph-col-12 {
    width: 100%;
}

.loader.hidden {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s .5s, opacity .5s linear;
}
