/* ========================================================================== */
/* ANIMATIONS & TRANSITIONS VARS */
/* CSS3 animations and transitions vars */
/* ========================================================================== */


/* Transitions */
$transition-speed: 			.4s !default;
$transition-fn: 			cubic-bezier(.02,.84,.59,1) !default;
$transition-all:            all $transition-speed $transition-fn !default;
$transition-fade:           .2s opacity ease-in-out !default;
$transition-simple:         .2s all ease-in !default;
