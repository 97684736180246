/* ========================================================================== */
/* TEMP STYLES */
/* Temporary styles for demo  */
/* ========================================================================== */


/* Lettering */
/* ========================================================================== */
#demo-lettering
{
	display: flex;
	background: $gray-150;

	&-list
	{
		width: 722px;
		flex-shrink: 0;
		flex-grow: 0;
	}

	&-result
	{
		flex: 1;
		padding: $spacing-md;
	}
}

/* Seller CCV */
/* ========================================================================== */
#demo-seller-ccv-transactions
{
	overflow-x: auto;
	text-align: center;
}