/* ========================================================================== */
/* COMPONENTS */
/* Components vars */
/* ========================================================================== */

/* Paging
 * ========================================================================== */
$pagination-link-width: 			px-to-rem(32px) !default;
$paging-content-padding:			$grid-gutter-width 0 !default;



// Offcanvas
$zindex-offcanvas-backdrop:         1040 !default;
$zindex-offcanvas:                  1100 !default;
$zindex-modal-backdrop:             1050 !default;
// scss-docs-start offcanvas-variables
$offcanvas-padding-y:               $spacing !default;
$offcanvas-padding-x:               $modal-inner-padding !default;
$offcanvas-horizontal-width:        25% !default;
$offcanvas-vertical-height:         30vh !default;
$offcanvas-transition-duration:     .3s !default;
$offcanvas-border-color:            $modal-content-border-color !default;
$offcanvas-border-width:            $modal-content-border-width !default;
$offcanvas-title-line-height:       $modal-title-line-height !default;
$offcanvas-bg-color:                $body-bg !default;
$offcanvas-color:                   $body-color !default;
$offcanvas-box-shadow:              $modal-content-box-shadow-xs !default;
$offcanvas-backdrop-bg:             $modal-backdrop-bg !default;
$offcanvas-backdrop-opacity:        $modal-backdrop-opacity !default;
// scss-docs-end offcanvas-variables
