// stylelint-disable function-disallowed-list

// BOOTSTRAP BACKPORTS

%offcanvas-css-vars {
	// scss-docs-start offcanvas-css-vars
	--offcanvas-zindex: 1100; // above modals
	--offcanvas-width: #{$offcanvas-horizontal-width};
	--offcanvas-height: #{$offcanvas-vertical-height};
	--offcanvas-padding-x: #{$offcanvas-padding-x};
	--offcanvas-padding-y: #{$offcanvas-padding-y};
	--offcanvas-color: #{$offcanvas-color};
	--offcanvas-bg: #{$offcanvas-bg-color};
	--offcanvas-border-width: #{$offcanvas-border-width};
	--offcanvas-border-color: #{$offcanvas-border-color};
	--offcanvas-box-shadow: #{$offcanvas-box-shadow};
	--offcanvas-transition: #{transform $offcanvas-transition-duration ease-in-out};
	--offcanvas-title-line-height: #{$offcanvas-title-line-height};
	// scss-docs-end offcanvas-css-vars
}

@each $breakpoint in map-keys($grid-breakpoints) {
	$next: breakpoint-next($breakpoint, $grid-breakpoints);
	$infix: breakpoint-infix($next, $grid-breakpoints);

	.offcanvas#{$infix} {
		@extend %offcanvas-css-vars;
	}
}

@each $breakpoint in map-keys($grid-breakpoints) {
	$next: breakpoint-next($breakpoint, $grid-breakpoints);
	$infix: breakpoint-infix($next, $grid-breakpoints);

	.offcanvas#{$infix} {
		@include media-breakpoint-down($next) {
			position: fixed;
			bottom: 0;
			z-index: var(--offcanvas-zindex);
			display: flex;
			flex-direction: column;
			max-width: 100%;
			color: var(--offcanvas-color);
			visibility: hidden;
			background-color: var(--offcanvas-bg);
			background-clip: padding-box;
			outline: 0;
			@include box-shadow(var(--offcanvas-box-shadow));
			@include transition(var(--offcanvas-transition));

			&.offcanvas-start {
				top: 0;
				left: 0;
				width: var(--offcanvas-width);
				border-right: var(--offcanvas-border-width) solid var(--offcanvas-border-color);
				transform: translateX(-100%);
			}

			&.offcanvas-end {
				top: 0;
				right: 0;
				width: var(--offcanvas-width);
				border-left: var(--offcanvas-border-width) solid var(--offcanvas-border-color);
				transform: translateX(100%);
			}

			&.offcanvas-top {
				top: 0;
				right: 0;
				left: 0;
				height: var(--offcanvas-height);
				max-height: 100%;
				border-bottom: var(--offcanvas-border-width) solid var(--offcanvas-border-color);
				transform: translateY(-100%);
			}

			&.offcanvas-bottom {
				right: 0;
				left: 0;
				height: var(--offcanvas-height);
				max-height: 100%;
				border-top: var(--offcanvas-border-width) solid var(--offcanvas-border-color);
				transform: translateY(100%);
			}

			&.showing,
			&.show:not(.hiding) {
				transform: none;
			}

			&.showing,
			&.hiding,
			&.show {
				visibility: visible;
			}
		}

		@if not ($infix == "") {
			@include media-breakpoint-up($next) {
				--offcanvas-height: auto;
				--offcanvas-border-width: 0;
				background-color: transparent !important; // stylelint-disable-line declaration-no-important

				.offcanvas-header {
					display: none;
				}

				.offcanvas-body {
					display: flex;
					flex-grow: 0;
					padding: 0;
					overflow-y: visible;
					// Reset `background-color` in case `.bg-*` classes are used in offcanvas
					background-color: transparent !important; // stylelint-disable-line declaration-no-important
				}
			}
		}
	}
}

.offcanvas-backdrop {
	@include overlay-backdrop($zindex-offcanvas-backdrop, $offcanvas-backdrop-bg, $offcanvas-backdrop-opacity);

	&-zindex {
			z-index: 9999;
	}
}

// stacked backdrops
.offcanvas + .offcanvas-backdrop {
	z-index: 10000;
}

// stacked canvas + dropdown
.offcanvas + .dropdown {
	z-index: 10052 !important
}

.offcanvas-panel {
	&-zindex {
		z-index: 10000;
	}

	&-large {
		width: 35% !important;
		padding: 1.5rem;
	}
}

.offcanvas-header {
	display: flex;
	align-items: center;
	padding: var(--offcanvas-padding-y) var(--offcanvas-padding-x);

	.btn-close {
		padding: calc(var(--offcanvas-padding-y) * .5) calc(var(--offcanvas-padding-x) * .5);
		margin: calc(-.5 * var(--offcanvas-padding-y)) calc(-.5 * var(--offcanvas-padding-x)) calc(-.5 * var(--offcanvas-padding-y)) auto;
	}
}

.offcanvas-title {
	margin-bottom: 0;
	line-height: var(--offcanvas-title-line-height);
}

.offcanvas-body {
	flex-grow: 1;
	padding: var(--offcanvas-padding-y) var(--offcanvas-padding-x);
	overflow-y: auto;
}


// CUSTOM

.offcanvas-header
{
	h1, h2, h3 {
		margin-bottom: 0;
	}

	.modal-button-close {
		margin-left: auto;
	}
}

.offcanvas-panel {
	&-xxlarge {
		width: 85% !important;
		padding: 1.5rem;
	}
}