/* ========================================================================== */
/* LETTERING VARS */
/* Lettering pages vars */
/* ========================================================================== */

$lettering-sidebar-w: 				380px !default;
$lettering-bg:		 				$gray-150 !default;
$lettering-misc-operation-item-bg:	$gray-100 !default;
$lettering-totals-item-bg:			$gray-100 !default;
$lettering-table-padding:			px-to-rem(15px) !default;
$lettering-checkbox-w:				40px !default;