/* ========================================================================== */
/* SELLERS PAGE */
/* Seller's page specific styles  */
/* ========================================================================== */



/* Seller details page  */
/* ========================================================================== */
.seller-identity-infos
{
	.content-block-main
	{
		@include media-breakpoint-up(lg) 
		{
			flex: 1;
		}

		.seller-identity-infos-bottom {
			.table-flex {
				min-width: auto;
			}
		}
	}
}

/* Seller identity KYC  */
/* ========================================================================== */
.seller-identity-kyc
{
	&-autorization
	{
		display: flex;
		flex-wrap: wrap;

		.form-check
		{
			margin-right: $spacing-xl;
		}
	}

	.content-block
	{
		@include border(null, $gray-100);
	}
}

.kyc-content {
	background: $white;
	padding: $content-block-padding;
	border-bottom: $main-border;

	.table {
		th, td {
			width: 25%;
		}
	}
}
