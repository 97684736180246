/* ========================================================================== */
/* LIST DEFINITION */
/* List definition component styles */
/* ========================================================================== */

/* Infos list
 * ========================================================================== */
%infos-list-label
{
	color: $gray-500;
	text-transform: uppercase;
	font-size: $font-size-sm;
}

.list-paging
{
	.table-nested-wrapper &
	{
		background-color: $table-bg;
	}
}

.infos-list
{
	display: flex;
	flex-wrap: wrap;
	position: relative;

	+ h2
	{
		margin-top: 2rem;
	}

	&:after
	{
		content: '';
		@include position-absolute(null, 0, 0, 0);
		@include border-bottom($content-block-bg, 2px);
	}

	li
	{
		&:not(.address-toggle) {
			@include border-bottom($list-item-border-color);
		}

		padding: $spacing-xs $spacing $spacing-xs 5px; // for the space between the columns
		flex-basis: 50%;
		flex-grow: 0;

		&.address-toggle {
			padding-top: $spacing;
			padding-bottom: 0px;
		}
	}

	.clear-full-width
	{
		flex-basis: 100%;
		padding: 0;
		border: none;
	}

	.full-width
	{
		flex-basis: 100%;
		@include padding-x(5px);
	}

	.tier-width
	{
		@include media-breakpoint-up(lg)
		{
			flex-basis: ratio-to-percentage(0.3333);
		}
	}

	.quarter-width
	{
		@include media-breakpoint-up(lg)
		{
			flex-basis: (100 * .25) * 1%;
		}
	}

	.has-overflow
	{
		overflow-x: auto;
	}

	.label
	{
		@extend %infos-list-label;
		margin-bottom: 3px;

		.badge
		{
			font-size: $font-size-xs;
		}
	}

	.value
	{
		@include flex-spacebetween(center);
		@include text-semi-bold;
		flex-wrap: wrap;
		margin-bottom: 0;
	}

  &.with-pending .value
  {
    padding: 4px;
  }

	:not(.input-group) > .form-control
	{
		margin-bottom: 5px;
		width: auto;
	}
}


/* Labelled list
* ========================================================================== */
.labelled-list
{
	color: $body-color;

	&-right
	{
		text-align: right;
	}

	.label
	{
		@extend %infos-list-label;
		margin-right: 5px;
	}
}


/* Files list
* ========================================================================== */
.files-list
{
	flex-wrap: wrap;
	align-content: flex-start;

	&,
	&-empty
	{
		display: flex;
		border: $files-list-border;
		height: 100%;
		overflow: auto;
	}

	/* Files list empty */
	&-empty
	{
		@include flex-center(column);

		&:before
		{
			@extend %fa-icon;
			@extend .far;
			content: fa-content($fa-var-paperclip);
			color: $files-list-empty-icon-color;
			font-size: $files-list-empty-icon-size;
		}
	}

	&-instructions
	{
		margin: $spacing 0;
		text-align: center;

		span
		{
			@include text-italic;
			color: $gray-600;
			display: block;
			font-size: $font-size-sm;
		}
	}

	/* Files list item */
	&-item
	{
		padding: $files-list-item-padding;
		width: $files-list-item-w;
		text-align: center;

		&-preview
		{
			display: block;
			word-break: break-all;
			font-size: $font-size-sm;
			margin: $spacing-xs auto 0;
			color: $body-color;
		}

		.btn-file-action
		{
			@extend .btn-reset;
			position: relative;
			color: $files-list-item-icon-color;

			&:before
			{
				@extend .fal;
				@extend %fa-icon;
				content: fa-content($fa-var-file);
				font-size: 48px;
			}

			span
			{
				@include circle(20px);
				@include position-absolute(null, -5px, -5px);
				cursor: pointer;
				display: flex;
				align-items: center;
				justify-content: center;
				border: 2px solid $files-list-item-icon-delete-color;
				background: $files-list-item-icon-delete-bg;
				color: $files-list-item-icon-delete-color;
				font-size: $font-size-sm;
			}

			&[disabled]
			{
				cursor: default;
				span { display: none; }
			}
		}

		&-remove
		{
			.btn-file-action
			{
				cursor: default;
			}
		}

		&-add
		{
			.btn-file-action
			{
				&:before
				{
					@extend .fas;
				}

				span
				{
					border-color: $files-list-item-icon-add-color;
					background: $files-list-item-icon-add-bg;
					color: $files-list-item-icon-add-color;
				}
			}
		}

	}
}



/* Links list
* ========================================================================== */
.links-list
{
	&-inline
	{
		li
		{
			display: inline-block;
			margin-right: $spacing-xs;
		}
	}

	a
	{
		@include link-underline;
	}
}



/* Settings list
* ========================================================================== */
.settings-list
{
	&-group-title:not(:first-of-type)
	{
		margin-top: 2em;
	}

	&-item
	{
		@include border-bottom($settings-list-border-color);
		display: flex;
		align-items: flex-start;
		padding: $spacing 0;

		&-label
		{
			flex: 0 0 $settings-list-label-w;
			font-size: $font-size-md;
		}

		&-value
		{
			flex: 1;
			padding-left: $spacing;
		}
	}
}


/* Document types
* ========================================================================== */

.files-list-item {
	.document-type-accountingdocument:before {
		content: "\f570";
	}

	.document-type-paymentschedule:before {
		content: "\f56c";
	}

	.document-type-accountstatement:before {
		content: "\f15c";
	}
}
