/* ========================================================================== */
/* LETTERING PAGES */
/* ========================================================================== */

/* Layout
 * ========================================================================== */
.accounting-lettering-detail-page
{
	.content-block
	{
		&-main
		{
			width: calc(100% - #{$lettering-sidebar-w});
		}

		&-sidebar
		{
			margin: 0;
			width: $lettering-sidebar-w;
			padding: $spacing-md $spacing;
			background: $lettering-bg;
			border-left: $content-block-border;

			h2
			{
				font-size: $font-size-md;
				color: $primary;
				text-transform: uppercase;
				margin: $spacing-xs 0 ($spacing-lg - $spacing-xs);
			}
		}
	}

	.table:not(.table-nested)
	{

		> thead th, > tbody tr:not(.table-collapsible-content) td
		{
			padding: $lettering-table-padding;
		}

		.col-checkbox
		{
			width: $lettering-checkbox-w;
			min-width: $lettering-checkbox-w;
			padding-left: 0;
			padding-right: 0;

			+ th, + td
			{
				padding-left: 0;
			}
		}
	}

	.list-paging
	{
		padding-left: 0;
		padding-right: 0;
	}
}

/* Lettering group
 * ========================================================================== */
.lettering-group
{
	$context: &;

	&-item
	{
		background: $card-item-bg-color;
		border-radius: $card-item-border-radius;
		border: $card-item-border;
		padding: $spacing-xs;
		margin: 0 0 $spacing-xs;

		&-opened
		{
			#{$context}-header
			{
				margin: 0 0 $spacing-xs;
			}
		}

		&-header
		{
			cursor: pointer;
			display: flex;
			justify-content: space-between;

			> i
			{
				font-size: $font-size-base;
				color: $secondary;
			}
		}

		&-title
		{
			flex: 1;
			font-size: $font-size-md;
			margin: 0;

			small
			{
				@include text-italic;
				font-size: $font-size-sm;
				color: $gray-450;
			}
		}

		&-row
		{
			display: flex;
			margin-top: $spacing-xs;

			& + &
			{
				border-top: $card-item-border;
				padding-top: $spacing-xs;
			}
		}

		&-col
		{
			flex-basis: ratio-to-percentage(0.3333);
			flex-grow: 0;
			flex-shrink: 0;
			font-size: px-to-rem(13px);

			span
			{
				@extend .form-label;
				display: block;
				font-size: px-to-rem(11px);
			}

			strong
			{
				word-break: break-word;
			}
		}

		.btn-delete
		{
			background: $dark;
			color: $white;
		}
	}

	&-misc-operation
	{
		background: $lettering-misc-operation-item-bg;

		#{$context}-item-header
		{
			cursor: default;
		}

		.btn-delete
		{
			background: $dark;
			color: $white;
		}
	}

	&-misc-operation
	{
		background: $lettering-misc-operation-item-bg;

		#{$context}-item-header
		{
			cursor: default;
		}
	}

	&-totals
	{
		h3
		{
			margin: 0 0 5px;
			font-size: $font-size-sm;
			text-transform: uppercase;
		}

		#{$context}-item
		{
			border-color: #cdd3e5;
			background: $gray-200;

			&-row
			{
				margin-top: 0;
			}
		}
	}
}
