.ageingBalance-table {
	td {
		vertical-align: middle;
	}

	.col-btn-actions {
		color: black;
		cursor: pointer;
	}

	input {
		width: 140px;
		&,
		&::-webkit-inner-spin-button,
		&::-webkit-outer-spin-button {
		  -webkit-appearance: none;
		  appearance: none;
		}
	}
	input[type=number] {
		-moz-appearance: textfield;
	}
}