/* ========================================================================== */
/* TABS */
/* Tabs component styles */
/* ========================================================================== */

.nav-tabs
{
	@include border-bottom($nav-divider-color);
	overflow-x: auto;
	display: flex;
	margin-bottom: $spacing;

	li:not(:first-child)
	{
		margin-left: $spacing-md;
	}

	a
	{
		@include border-bottom($nav-tabs-border-color,  $nav-tabs-border-width);
		display: block;
		padding-bottom: $spacing-xs;
		color: $nav-tab-link-color;
		font-size: $font-size-base;
		white-space: nowrap;

		&:hover
		{
			border-color: $nav-tabs-link-hover-border-color;
			text-decoration: none;
		}

		&.active
		{
			border-color: $nav-tabs-link-active-border-color;
		}

		@include media-breakpoint-up(lg)
		{
			font-size: $tabs-font-size;
		}
	}
}


/* Control tabs
* ========================================================================== */
#content .control-tabs
{
	$context: &;

	&-item
	{
		display: flex;
		align-items: center;
		padding: 15px;
	}

	&-blocked
	{
		#{$context}-item
		{
			cursor: not-allowed;
		}
	}

	.nav-tabs
	{
		counter-reset: nav-tabs-counter;
		justify-content: space-between;
		border-bottom: 0;
		margin-bottom: -1px;

		li:not(:first-child)
		{
			margin-left: $spacing;
		}
	}

	.nav-item
	{
		
		counter-increment: nav-tabs-counter;
		@include border(null, $content-border-color);
		border-bottom: 0;
		flex: 1;
		background: $control-tabs-bg;
		border-top-left-radius: $border-radius;
		border-top-right-radius: $border-radius;

		a
		{
			padding-bottom: $spacing;
			opacity: $control-tabs-opacity;
			border-bottom: $control-tabs-border;

			&.active
			{
				opacity: $control-tabs-active-opacity;
				border-bottom-color: $control-tabs-bg;
			}

			&.disabled
			{
				cursor: not-allowed;
			}
		}
	}

	.tab-status-badge
	{
		@include circle($control-tabs-bullet-size);
		margin-right: $spacing-xs;
		background: $control-tabs-status-color;
		color: $white;
		flex-shrink: 0;

		&:before
		{
			content: counter(nav-tabs-counter);
		}

		&.danger
		{
			background: $danger;
		}

		&.warning
		{
			background: $warning;
		}

		&.success
		{
			background: $success;

			&:before
			{
				@extend %fa-icon;
				@extend .fas;
				content: fa-content($fa-var-check);
			}
		}
	}

	.tab-status
	{
		&-step
		{
			color: $control-tabs-color;
			font-size: $control-tabs-step-font;
			text-transform: uppercase;
		}

		&-label
		{
			line-height: 1;
		}
	}

	.tab-pane
	{
		background: $content-block-bg;
		box-shadow: $content-block-shadow;
		border-radius: 0 0 $border-radius $border-radius;
		border: $content-block-border;
		padding: $content-block-padding;
	}

	// margin for the dropdowns in all the table-flex of those tabs
	.table-flex
	{
		margin-bottom: 60px;
	}
}
