/* ========================================================================== */
/* TRANSFERS PAGE */
/* Transfer's page specific styles  */
/* ========================================================================== */


/* Transfer page  */
/* ========================================================================== */
.transfer-control-block
{
	@include generic-block-style($transfer-quote-margin, $transfer-quote-padding);

	&-select
	{
		display: flex;
		align-items: center;
	}

	&-info
	{
		@include text-bold;
		color: $gray-800;
		font-size: $font-size-md;
	}

	.form-label
	{
		padding-right: $spacing-xs;
	}
}

/* Transfer component quote  */
/* ========================================================================== */
.transfer-control-quote
{
	&-row
	{
		@include flex-spacebetween(flex-end);

	}

	.transfer-control-block-label
	{
		.form-label
		{
			width: $transfer-quote-label-width;
		}

	}
}

/* Transfer component survey  */
/* ========================================================================== */
.transfer-control-survey
{
	.transfer-control-block-label
	{
		.form-label
		{
			width: $transfer-survey-label-width;
		}

		.btn-link
		{
			margin-top: 10px;
		}
	}

	&-buttons
	{
		@include flex-spacebetween(center);
		flex-wrap: wrap;
	}

	&-select
	{
		display: flex;
		align-items: center;
		margin-left: auto;

		.form-label
		{
			margin-right: $spacing-xs;
		}
	}

	&-form
	{
		h3
		{
			color: $primary;
			margin: 2em 0 1em;
		}
	}

	&-file-list
	{
		margin: $spacing-xs 0 0;
	}
}


/* Transfer component validation  */
/* ========================================================================== */
.transfer-control-validation
{
	.transfer-control-block-label
	{
		.form-label
		{
			width: $transfer-validation-label-width;
		}

		.label
		{
			&-warning
			{
				&:before
				{
					@extend %fa-icon;
					@extend .fas;
					content: fa-content($fa-var-exclamation-triangle);
					color: $danger;
					margin-right: 5px;
				}
			}
		}
	}
}
