.theme {

    &-picture {
        width: 98px;
        height: 98px;
        border: 1px solid #555;
        border-radius: 2px;
        background: #fff;

        &:hover {
            box-shadow: 1px 1px 1px 1px #999;
        }
    }

    &-header {
        height: 15px;
        margin: 0 auto 5px auto;
        background: #eee;
    }

    &-nav {
        height: 70px;
        width: 15px;
        float: left;
        background: #eee;
    }

    &-background {
        position: relative;
        height: 73px;
        width: 76px;
        padding-top: 3px;
        left: 20px;
        background: #eee;

        .theme-text {
            height: 10px;
            width: 50px;
            margin: 8px 0 8px 2px;
            background: #fff;

            &.short {
                width: 30px;
            }    
        }
    }
}