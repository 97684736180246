/* ========================================================================== */
/* PAGING */
/* Paging component styles */
/* ========================================================================== */


/* Bootstrap overrides
 * ========================================================================== */
.pagination
{
	margin-bottom: 0;
}

.page-item
{
	&:first-child,
	&:last-child
	{
		.page-link:before
		{
			@extend %fa-icon;
			@extend .fal;
		}
	
		span { display: none; }
	}

	&:first-child .page-link:before { content: fa-content($fa-var-angle-left); }
	&:last-child .page-link:before { content: fa-content($fa-var-angle-right); }
}

.page-link
{
	min-width: $pagination-link-width;
	border-radius: $border-radius;
	text-align: center;
}


/* List paging (page range and pagination)
 * ========================================================================== */
.list-paging
{
	@include flex-spacebetween(center);
	padding: $paging-content-padding;

	&-toggle
	{
		justify-content: flex-end;
	}

	.modal-content &
	{
		@include padding-x(0);
	}

	&-sizes
	{
		select
		{
			@include align-inline-block;
			width: auto;
			margin: 0 $spacing-xs;
		}
	}

	&-total
	{
		margin-left: 3px;
	}
}

