/* ========================================================================== */
/* POPOVER */
/* Popover component styles */
/* ========================================================================== */

.popover {
	max-width: 500px;
}

.popover-content
{
	& + &
	{
		margin-top: $popover-margin-top;
	}

	&-label
	{
		@extend .form-label;
	}

	&-text
	{
		line-height: 1;
		
		&-sm
		{
			font-size: $font-size-sm;
		}
		
		&-md
		{
			@include text-bold;
			font-size: $font-size-md;
		}
	}

	&-button-text {
		font-family: $font-family-base;
	}
}