/* ========================================================================== */
/* COMMENTS */
/* Comment component styles */
/* ========================================================================== */

/* Form comments component */
 .form-comment
 {
	&-label
	{
		padding-bottom: $spacing-xs;
		color: $comment-label-color;
		font-size: $font-size-sm;
	}

	&-list
	{
		margin-top: $spacing;
	}

	&-inner
	{
		padding-top: $spacing-lg;
	}

	&-input
	{
		display: flex;
 
		mwl-text-input-autocomplete-container + button {
			height: 3em;
			margin-left: 5px;
		}

		textarea
		{
			resize: vertical;

			&+ .btn
			{
				align-self: flex-start;
				margin-left: $spacing-xs;
			}
		}
	}
 }

 /* Comment component */
 .comment-item
 {
	&-head
	{
		margin-bottom: $spacing-xs;
	}

	.card-item-body {
		word-break: break-word;
		white-space: pre-wrap; // to preserve newlines

		.ngx-text-highlight {
			color: $primary-light;
		}
	}
 }
 
/* Comments children */
.comments-children
{
	&-collapsed {
		.comments-children-toggle {
			flex: 0 0 100%;
			max-width: 100%;
		}
	}

	&-toggle {
		flex: 0 0 1%;
		cursor: pointer;
		border-left: $spacing-xs*.5 solid $light;
		margin-right: 10px;
		margin-bottom: 10px;
	}

	&-content {
		flex: 0 0 calc(100% - 1% - 10px);
		max-width: calc(100% - 1% - 10px);
	}
}

