.graph {
	overflow: hidden;
}

.home-refresh-alert .btn-link,
.home-kpis .kpis-header .btn-link {
	cursor: pointer;
}

.home-table-container {
	overflow-x: scroll;
}

.home-kpis .kpis-section {

	.kpi-double-item {
		width: 100%;
	}

	.kpis-header {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	}
}

home-page .graph-block,
.super-debtor-kpi-charts .graph-block-large-height {
	min-height: 400px;
}

graph-loader {
	pointer-events: none;
	position: absolute;
	min-height: 300px;
	min-width: 800px;
	height: 100%;
	width: 100%;
	left: 0;
	top: 0;
}

.home-charts-turnover-world-map {
	.chart-legend {
		position: absolute;
		left: 30px;

		.legend-wrap {
			width: 100%;
		}

		.legend-labels {
			background: rgba(240, 240, 240, 0.7);
		}

	}

}

.home-charts-filters {

	/* align the filters block */
	.active-filters-wrap {
		padding-left: 5px;
	}
}