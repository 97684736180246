/* ========================================================================== */
/* MIXINS */
/* SASS Mixins */
/* ========================================================================== */

/* Position
 * ========================================================================== */
@mixin position($type: absolute, $top: null, $right: null, $bottom: null, $left: null)
{
	position: $type;
	top: $top;
	right: $right;
	bottom: $bottom;
	left: $left;
}

@mixin position-relative($top: null, $right: null, $bottom: null, $left: null)
{
	@include position(relative, $top, $right, $bottom, $left);
}

@mixin position-absolute($top: null, $right: null, $bottom: null, $left: null)
{
	@include position(absolute, $top, $right, $bottom, $left);
}

@mixin position-fixed($top: null, $right: null, $bottom: null, $left: null)
{
	@include position(fixed, $top, $right, $bottom, $left);
}

@mixin pos-center($position: absolute)
{
	position: $position;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

@mixin pos-center-x($position: absolute)
{
	position: $position;
	left: 50%;
	transform: translateX(-50%);
}

@mixin pos-center-y($position: absolute)
{
	position: $position;
	top: 50%;
	transform: translateY(-50%);
}

/* Lists
 * ========================================================================== */
@mixin list-reset
{
	list-style: none;
	padding-left: 0;
}

/* Fonts
 * ========================================================================== */
@mixin text-normal
{
	font-weight: $font-weight-normal;
}

@mixin text-italic
{
	@include text-normal;
	font-style: italic;
}

@mixin text-light
{
	font-weight: $font-weight-light;
}

@mixin text-light-italic
{
	@include text-light;
	font-style: italic;
}

@mixin text-semi-bold
{
	font-weight: $font-weight-semi-bold;
}

@mixin text-semi-bold-italic
{
	@include text-semi-bold;
	font-style: italic;
}

@mixin text-bold
{
	font-weight: $font-weight-bold;
}

@mixin text-bold-italic
{
	@include text-bold;
	font-style: italic;
}

/* Text formating
 * ========================================================================== */
@mixin link-underline
{
	text-decoration: underline;

	&:hover
	{
		text-decoration: none;
	}
}

@mixin text-ellipsis($max-width: 0)
{
	max-width: $max-width;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
	
/* Block formating
 * ========================================================================== */
@mixin rectangle($width, $height: $width)
{
	width: $width;
	height: $height;
}

@mixin align-inline-block($align:middle)
{
	display: inline-block;
	vertical-align: $align;
}

@mixin flex-center($direction:null, $display: flex) 
{
	display: $display;
	align-items: center;
	justify-content: center;
	@if $direction != null { flex-direction: $direction; }
}

@mixin flex-spacebetween($alignment, $direction:null, $display: flex) 
{
	display: $display;
	justify-content: space-between;
	align-items: $alignment;
	@if $direction != null { flex-direction: $direction; }
}

@mixin circle($size:null)
{
	@if $size != null { @include rectangle($size); }
	@include flex-center;
	border-radius: 50%;
	padding: 0;
}

@mixin losange($size:null, $background: transparent)
{
	@if $size != null { @include rectangle($size); }
	content: '';
	background: $background;
	transform: rotate(45deg);
}


/* Animation
 * ========================================================================== */
@mixin toggle-animation($selector, $max-height, $opacity:null, $transition-close:null, $transition-open:null)
{
	max-height: 0;
	opacity: $opacity;
	transition: $transition-close;

	#{$selector}
	{
		max-height: $max-height;
		opacity: 1;
		transition: $transition-open;
	}
}

@mixin toggle-animation-reverse($selector, $max-height, $transition-close:null, $transition-open:null)
{
	visibility: visible;
	max-height: $max-height;
	opacity: 1;
	transition: $transition-open;

	#{$selector}
	{	
		visibility: hidden;
		max-height: 0;
		opacity: 0;
		transition: $transition-close;
	}
}

/* Padding
 * ========================================================================== */
@mixin padding-x($padding: 0)
{
	padding-left: $padding;
	padding-right: $padding;
}

@mixin padding-y($padding: 0)
{
	padding-top: $padding;
	padding-bottom: $padding;
}

/* Borders
 * ========================================================================== */
@mixin border($direction: null, $color: $border-color, $width: $border-width)
{
	$val: $width solid $color;
	$prop: border;
	
	@if $direction 
	{
		$prop: $prop + '-' + $direction;
	}

	#{$prop}: $val;
}

@mixin border-top($color: $border-color, $width: $border-width)
{
	@include border(top, $color, $width)
}

@mixin border-right($color: $border-color, $width: $border-width)
{
	@include border(right, $color, $width)
}

@mixin border-bottom($color: $border-color, $width: $border-width)
{
	@include border(bottom, $color, $width)
}

@mixin border-left($color: $border-color, $width: $border-width)
{
	@include border(left, $color, $width)
}


/* Buttons
 * ========================================================================== */
@mixin btn-reset()
{
	background: 0;
	border: 0;
	padding: 0;

	&:focus
	{
		outline: 0;
	}
}

@mixin btn-block($margin: 0 0 $spacing-xs)
{
	display: block;
    width: 100%;
	margin: $margin;
}

@mixin btn-rounded($size: null)
{
	@include circle($size);
}

/* Other
 * ========================================================================== */
@mixin no-input
{
	input 
	{
		display: none;
	}
}

@mixin shadow-block($background: transparent, $shadow: $box-shadow, $radius: $border-radius)
{
	background: $background;
	border-radius: $radius;
	box-shadow: $shadow;
}

@mixin generic-block-style($margin: auto, $padding: 0)
{
	@include border(null, $content-border-color);
	@include shadow-block(null, $content-box-shadow);
	margin: $margin;
	padding: $padding;
}

@mixin bullet($size: $spacing-xs) 
{
	@include circle($size);
	content: '';
	flex-shrink: 0;
	margin-right: 5px;
}

// Shared between modals and offcanvases
@mixin overlay-backdrop($zindex, $backdrop-bg, $backdrop-opacity) {
	position: fixed;
	top: 0;
	left: 0;
	z-index: $zindex;
	width: 100vw;
	height: 100vh;
	background-color: $backdrop-bg;
  
	// Fade for backdrop
	&.fade { opacity: 0; }
	&.show { opacity: $backdrop-opacity; }
}
  