/* ========================================================================== */
/* APP CONTAINER */
/* App's layout's sections positionning */
/* ========================================================================== */

/* Common app structure
 * ========================================================================== */
#app
{
    display: flex;
    height: 100vh;
}

#menu,
#top-bar
{
    flex-shrink: 0;
    flex-grow: 0;
}

/* Empty pages
 * ========================================================================== */
.no-available-data
{
    font-style: italic;
    color: $gray-450;
}

/* Anonymous layout structure
 * ========================================================================== */
.anonymous-layout
{
	#content {}
}

/* Authentication layout structure
 * ========================================================================== */
.authentication-layout
{
    justify-content: center;
    align-items: center;
}

/* Authenticated layout structure
 * ========================================================================== */
.authenticated-layout
{
	#content
	{
        z-index: 1;
        display: flex;
        flex-direction: column;
        flex: 1;
        overflow-x: hidden;
        width: calc(100% - #{$menu-w});
        transition: $content-transition;
        will-change: margin-left;
    }

	&.menu-collapsed #content
	{
        width: auto;
        margin-left: -$menu-hidden-w;
    }

	#main
	{
        flex: 1;
        overflow: auto;
    }
}

/* Notifications toaster
 * ========================================================================== */
.page-notifications
{
    position: fixed;
    right: $spacing;
    bottom: $spacing;
    width: $menu-w * 2;
    z-index: $notifications-zindex;
}

.notification-message
{
    margin: $spacing-xs 0 0;

	&.animated
	{
        opacity: 0;
        transform: translateX(50%);
        transition: $transition-all;

		&.in
		{
            opacity: 1;
            transform: translateX(0%);
        }
    }
}

/* Fixed layout header
 * ======================================================================== */
.fixed-layout
{
	> page-header .page-header
	{
        transition: $transition-simple;
		background: $body-bg;
		padding-top: 2px;
		position: fixed;
		z-index: 2;
		height: 50px;
		width: 100%;
		top: 71px;

		h1 { font-size: 1.5rem; }
	}

	> .nav-tabs.nav
	{
        transition: $transition-simple;
		background: $body-bg;
		position: fixed;
		z-index: 2;
		width: 100%;
		top: 100px;
	}
}
