.themes-list
{
    &-item {
        max-width: 120px;
        margin: 40px;
    }

    .theme-name {
        text-align: center;
    }
}