/* ========================================================================== */
/* TASKS PAGE */
/* Tasks' page specific styles  */
/* ========================================================================== */

.taskboard
{
	min-height: $loader-spinner-size;
}

/* Taskboard filters */
/* ========================================================================== */
.taskboard-filters
{
	@include position-absolute($taskboard-filters-y, $taskboard-filters-x);
	text-align: right;

	&-switch
	{
		margin-bottom: $taskboard-filters-bottom;
	}
}


/* Taskboard groups */
/* ========================================================================== */
.taskboard-groups
{
	.content-block
	{
		padding: $spacing;

		&-header
		{
			margin-bottom: $spacing;
		}
	}
}