/* ========================================================================== */
/* TIMELINE */
/* Timeline component styles */
/* ========================================================================== */

.timeline
{
	&-container
	{
		opacity: 1;
		transition: opacity 0.2s;
		
		&.loaded { opacity: 1; }
	}

	/* Timeline events
	* ========================================================================== */	
	&-events
	{
		&-container
		{
			display: flex;
			justify-content: space-between;
			align-items: flex-start;
		}

		/* Slider container */
		&-slider
		{
			flex: 1;
			margin: $dunning-scenario-timeline-slider-margin;
			position: relative;
			overflow: hidden;

			&:after
			{
				content: '';
				display: block;
				background: $dunning-scenario-timeline-bar-bg;
			}

			&.has-previous {
				box-shadow: $events-slider-shadow-prev;
			}
			&.has-next {
				box-shadow: $events-slider-shadow-next;
			}
			&.has-previous.has-next {
				box-shadow: $events-slider-shadow-prev, $events-slider-shadow-next;
			}
		}
		
		&-list
		{
			display: flex;
			transition: transform 0.4s;
			position: relative;
			z-index: 2;
		}

		&-slider:after,
		&-filling-bar
		{
			@include position-absolute($dunning-scenario-timeline-bar-offset, 0, null, 0);
			width: 100%;
			height: $dunning-scenario-timeline-bar-h;
			z-index: 1;
		}

		&-filling-bar
		{
			background: $dunning-scenario-timeline-filling-bar-bg;
			transform: scaleX(0) translateZ(0);
			transform-origin: left center;
			transition: $dunning-scenario-timeline-filling-bar-transition;
		}

		/* Slider item */
		&-item
		{
			$className: &;

			flex-shrink: 0;
			width: $dunning-scenario-timeline-event-w;
			text-align: center;
			color: $dunning-scenario-timeline-event-color;
			position: relative;
			z-index: 2;

			&-btn
			{
				@extend .btn-reset;
				display: flex;
				flex-direction: column;
				align-items: center;
				margin: 0 auto;
				color: inherit;
			}

			&-caption
			{
				@include circle($dunning-scenario-timeline-event-caption-size);
				@include flex-center;
				background: $dunning-scenario-timeline-event-caption-bg;
				color: $dunning-scenario-timeline-event-caption-color;
				box-shadow: $dunning-scenario-timeline-event-caption-border; 
			}

			&-date
			{
				display: block;
				padding: $spacing 0 0;
			}

			&-icon
			{
				margin-bottom: 5px;
				color: $dunning-scenario-timeline-event-caption-bg;
			}

			&-icon-badge
			{
				font-size: 50%;
				position: absolute;
				top: 10px;
			}

			&.is-passed
			{
			}

			&.is-active
			{
				color: $dunning-scenario-timeline-event-active-color;

				#{$className}-caption
				{
					background: $dunning-scenario-timeline-event-active-caption-bg;
					color: $dunning-scenario-timeline-event-active-caption-color;
				}
			}
		}

		/* With Icons */
		&-with-step-icons {
			.timeline-events-btn-nav, .timeline-events-btn-add
			{
				margin-top: 50px;
			}
			.timeline-events-slider::after, .timeline-events-filling-bar
			{
				top: 60px;
			}
		}

		/* Actions */
		&-btn-nav,
		&-btn-add
		{
			@include btn-rounded($dunning-scenario-timeline-action-btn-size);
			border: 0;
			flex-shrink: 0;
			flex-grow: 0;

			i:before { font-size: $font-size-md; }
		}

		&-btn-add
		{
			margin-right: $spacing;	
		}
	}
	

	
	/* Timeline detail
	* ========================================================================== */	
	&-detail
	{
		margin: $dunning-scenario-timeline-detail-margin;

		&-empty
		{
			text-align: center;
		}

		&-list
		{
			height: 100%;
			overflow: hidden;
			position: relative;
		}

		&-item
		{
			transition: $transition-fade; 

			&.is-visible
			{
				position: relative;
				z-index: 2;
				opacity: 1;
				visibility: visible;
				transition-delay: .2s;
			}

			&.is-hidden
			{
				position: absolute;
				top: 0;
				z-index: 1;
				width: 100%;
				opacity: 0;
				visibility: hidden;
			}
		}
	}
}