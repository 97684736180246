/* ========================================================================== */
/* MODALS */
/* Modals formatting classes */
/* ========================================================================== */


/* Bootstrap overrides
* ========================================================================== */
.modal
{
	$modal-content-padding: $spacing-md;

	&-content
	{
		padding: $modal-content-padding;

		&-scrollable
		{
			// Backdrop vertical margin - Content vertical padding - Border vertical width
			max-height: calc(100vh - #{$modal-dialog-margin-y-sm-up} * 2 - #{$modal-content-padding} * 2 - #{$modal-content-border-width} *2);
			overflow: auto;
		}
	}

	&-header,
	&-footer
	{
		align-items: center;
		padding: 0;
	}

	&-header
	{
		padding-bottom: $spacing;

		h2
		{
			margin-bottom: 0;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}

	&-button-close
	{
		@extend .btn-reset;
		font-size: $font-size-xxl;
		color: $gray-450;
	}

	&-iframe
	{
		border: 0;
		height: 500px;
		width: 100%;
	}

	&-email
	{
		&-block
		{
			@include border-top($border-width, $modal-content-border-color);
			padding: $spacing-xs 0;

			p:last-child { margin-bottom: 0; }
		}

		&-subject
		{
			@include text-bold;
			font-size: $font-size-md;
			margin-bottom: .5rem;
			word-break: break-word;
		}

		&-date
		{
			color: $gray-450;
		}

		// Resetting some style to align with CKEditor
		&-content,
		&-subject table // Should not be a real use case
		{
			@include text-normal;
			font-family: sans-serif, Arial, Verdana, "Trebuchet MS";
			font-size: $font-size-base;
			line-height: 1.6;
			word-wrap: break-word;
			color: $modal-content-color;
		}

		&-content
		{
			min-height: 100px;

			ul
			{
				list-style: initial;
			}

			ol, ul, dl
			{
				margin-top: initial;
				margin-bottom: 1rem;
				padding: 0 40px;
			}
		}

		&-attachments-item {
			flex-basis: auto !important;
			border: none !important;

			.btn {
				text-overflow: ellipsis;
				text-wrap: nowrap;
				overflow: hidden;
				max-width: 250px;
			}
		}
	}

	&-body.email-writer .files-list-instructions {
		margin: 0;
		text-align: left;
	}

	@include media-breakpoint-up(md)
	{
		.modal-extended &-lg
		{
			max-width: $modal-xl;
		}
	
		.modal-super-extended &-lg
		{
			max-width: $modal-xxl;
		}
	}

	@include media-breakpoint-up(lg)
	{
		.modal-extended &-lg
		{
			max-width: $modal-xxl;
		}
		.modal-super-extended &-lg
		{
			max-width: $modal-xxxl;
		}
	}
}

// Temporary fix https://github.com/ng-bootstrap/ng-bootstrap/issues/2686
.modal-backdrop.fade.show {
	z-index: 1040 !important;
}
