/* ========================================================================== */
/* NOTIFICATIONS */
/* Notifications component styles */
/* ========================================================================== */

/* Notifications component */
.notifications-page {
	.content-block + .content-block {
		margin-top: 0;
	}

	.notifications-list-bar {
		height: 45px;
	}

	.table {

		tbody td {
			padding: 0.7rem 2.1428571429rem;
		}

		.notification-unread-badge {
			display: block;
			height: 100%;
			width: 6px;
			background: $secondary;
			position: absolute;
			top: 0;
			left: 0;
		}
	}

	.notifications-preferences-panel {
		margin-top: 25px;

		.notifications-head {
			margin-top: 1.75em;
		}

		.ml-auto {
			height: 40px;

			loader-spinner {
				position: absolute;
				top: 12px;
				left: 0;
			}
		}
	}

	notifications-filters {
		padding-top: 48px;

		h3 {
			margin-bottom: 44px;
		}

		.notifications-categories-header {

			button {
				font-size: small;
				color: #bbb;
			}
		}

		.notifications-categories-panel {

			li li {
				color: #666;

				&.active {
					color: $primary;
					font-weight: bolder;
				}
			}
		}
	}

	notifications-preferences {
		.notification-settings-header {
			margin-bottom: -2rem;
			z-index: 1000;

			i {
				width: 30px;
				font-size: 80%;
				text-align: center;
			}
		}

		.notification-setting-title
		{
			max-width: 280px;
		}
	}
}

/* Notifications component */
.side-panel
{
	@include position-absolute($notifications-top, -$notification-w, 0);
	z-index:3;
	width: $notification-w;
	display: flex;
	flex-direction: column;
	background: $notifications-bg;
	box-shadow: $notifications-shadow;
	transition: $notifications-transition;

	&.opened
	{
		right: 0;
	}

	.notifications
	{
		&-head
		{
			@include flex-spacebetween(center);
			padding: $notifications-head-padding;

			h2
			{
				margin-bottom: 0;
				font-size: $font-size-xl;
			}
		}

		&-list
		{
			flex: 1;
			overflow: auto;

			&-scroll {
				height: calc(100vh - 150px);
			}
		}

		.btn-filter:not(.active)
		{
			background: $notifications-filters-bg;
		}
	}
}

/* Notification item component */
.notification-item
{
	margin-bottom: 0;
	border: 0;
	border-bottom: $notification-item-border;
	border-radius: 0;
	cursor: pointer;

	&-read, &:hover
	{
		background: $notification-item-read-bg;
	}

	&-content-head
	{
		@include flex-spacebetween(center);
		width: 100%;
		margin-bottom: $spacing-xs;
	}

	&-rate-label, &-rate-value, &-link
	{
		font-size: $font-size-sm;
	}

	&-rate-label
	{
		color: $gray-300;
		text-transform: uppercase;
	}

	.name
	{
		margin-right: $notification-item-name-margin;
	}
}

.cursor-pointer {
   cursor: pointer;
}

.pe-none {
	pointer-events: none;
}

.actions-inactive {
	opacity: .3;
	pointer-events: none;
}
