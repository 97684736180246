/* ========================================================================== */
/* ICONFONT */
/* Custom Iconfont styles */
/* ========================================================================== */

.fac
{
	@extend %fa-icon;
	font-family: 'iconfont';
	font-weight: normal;
}


	$fa-var-custom-cash: \EA01;

	@mixin fa-custom-cash { content: fa-content($fa-var-custom-cash); }

	.fa-custom-cash:before { @include fa-custom-cash; }

	$fa-var-custom-clipboard: \EA02;

	@mixin fa-custom-clipboard { content: fa-content($fa-var-custom-clipboard); }

	.fa-custom-clipboard:before { @include fa-custom-clipboard; }

	$fa-var-custom-home: \EA03;

	@mixin fa-custom-home { content: fa-content($fa-var-custom-home); }

	.fa-custom-home:before { @include fa-custom-home; }

	$fa-var-custom-paperclip: \EA04;

	@mixin fa-custom-paperclip { content: fa-content($fa-var-custom-paperclip); }

	.fa-custom-paperclip:before { @include fa-custom-paperclip; }

	$fa-var-custom-portfolio: \EA05;

	@mixin fa-custom-portfolio { content: fa-content($fa-var-custom-portfolio); }

	.fa-custom-portfolio:before { @include fa-custom-portfolio; }

	$fa-var-custom-user: \EA06;

	@mixin fa-custom-user { content: fa-content($fa-var-custom-user); }

	.fa-custom-user:before { @include fa-custom-user; }

	$fa-var-custom-bell-off: \EA07;

	@mixin fa-custom-bell-off { content: fa-content($fa-var-custom-bell-off); }

	.fa-custom-bell-off:before { @include fa-custom-bell-off; }

	$fa-var-custom-bell-on: \EA08;

	@mixin fa-custom-bell-on { content: fa-content($fa-var-custom-bell-on); }

	.fa-custom-bell-on:before { @include fa-custom-bell-on; }

	$fa-var-custom-analyst: \EA10;

	@mixin fa-custom-analyst { content: fa-content($fa-var-custom-analyst); }

	.fa-custom-analyst:before { @include fa-custom-analyst; }

	$fa-var-custom-commentator: \EA11;

	@mixin fa-custom-commentator { content: fa-content($fa-var-custom-commentator); }

	.fa-custom-commentator:before { @include fa-custom-commentator; }

	$fa-var-custom-dunning: \EA12;

	@mixin fa-custom-dunning { content: fa-content($fa-var-custom-dunning); }

	.fa-custom-dunning:before { @include fa-custom-dunning; }

	$fa-var-custom-famous: \EA13;

	@mixin fa-custom-famous { content: fa-content($fa-var-custom-famous); }

	.fa-custom-famous:before { @include fa-custom-famous; }

	$fa-var-custom-organizer: \EA14;

	@mixin fa-custom-organizer { content: fa-content($fa-var-custom-organizer); }

	.fa-custom-organizer:before { @include fa-custom-organizer; }

	$fa-var-custom-finder: \EA15;

	@mixin fa-custom-finder { content: fa-content($fa-var-custom-finder); }

	.fa-custom-finder:before { @include fa-custom-finder; }

	$fa-var-custom-rigorous: \EA16;

	@mixin fa-custom-rigorous { content: fa-content($fa-var-custom-rigorous); }

	.fa-custom-rigorous:before { @include fa-custom-rigorous; }
