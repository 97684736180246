/* ========================================================================== */
/* LAYOUT VARS */
/* Layout's main sections vars */
/* ========================================================================== */

/* Grid
* ========================================================================== */
$spacing: 							$grid-gutter-width !default;
$spacing-xs:      					$spacing *.5 !default;
$spacing-md:      					$spacing * 2 !default;
$spacing-lg:      					$spacing * 3 !default;
$spacing-xl:      					$spacing * 4 !default;
$spacing-xxl:     					$spacing * 5 !default;


/* Loader
 * ========================================================================== */
$loader-spinner-size:               50px !default;
$loader-spinner-size-sm:            1em !default;
$loader-spinner-size-xl:            5em !default;
$loader-spinner-color:              $primary !default;
$loader-spinner-revert-color:       $white !default;
$loader-spinner-animation:          rotate 2s linear infinite !default;
$loader-spinner-path-animation:     dash 1.5s ease-in-out infinite !default;
$loader-message-color:              $black !default;

$page-loader-overlay-opacity: 		.8 !default;
$page-loader-overlay-bg:            rgba($white, $page-loader-overlay-opacity) !default;
$page-loader-overlay-zindex:        $zindex-modal-backdrop !default;
$page-loader-transition:            opacity $transition-speed linear !default;


/* Block style */
/* ========================================================================== */
$content-border-color:				#eceff5 !default;
$content-box-shadow:				0px 1px 2px 0 rgba($black, 0.06) !default;