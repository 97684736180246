/* ========================================================================== */
/* TEXTS */
/* Texts formatting classes */
/* ========================================================================== */


/* Bootstrap overrides
 * ========================================================================== */
.badge
{
	color: $white;
}

.text-initial
{
	text-transform: initial !important;
}

.text-pre-warp
{
	white-space: pre-wrap !important;
}

/* Divider
 * ========================================================================== */
.text-divider
{
	display: block;
	margin: 25px 0;
	text-align: center;
	font-size: $font-size-sm;
	line-height: 0;
	text-transform: uppercase;

	span
	{
		padding: 0 $spacing-xs;
		background: $content-block-bg;
	}

	&:before
	{
		@include border-top($gray-200);
		content: '';
		display: block;
	}
}

/* Tooltip
 * ========================================================================== */
.tooltip
{
	@include text-italic;
	animation: fadeInTooltip $transition-speed $transition-fn;
	pointer-events: none; // avoid blinking
}


/* Code
 * ========================================================================== */
.code
{
	@include border;
	padding: $spacing-xs;
	white-space: pre-line;
}

.pre {
	white-space: pre-line;
}

/* Page title icon
 * ========================================================================== */
[page-header-area="icon"] {
    position: relative;
    margin-right: 5px;
    font-size: 70%;
    top: -2px;
}
.tenant-logo[page-header-area="icon"] {
    background-repeat: no-repeat;
    background-position: left;
    background-size: contain;
	width: 100px;
    height: 50px;
    top: 6px;
}

/* List names icon
 * ========================================================================== */
.table .col-with-icon i {
    font-size: 80%;
    margin-right: $spacing-xs * .5;
}

.table .col-with-icon [class*=fa] {
  	color: $primary;
}
