/* ========================================================================== */
/* ICONFONT VARS */
/* Font Awesome overrides and custom Iconfont config */
/* ========================================================================== */

// Font awesome
$fa-font-path:                "@fortawesome/fontawesome-pro/webfonts" !default;
$fa-font-size-base:           16px !default;
$fa-css-prefix:               fa !default;
$fa-version:                  "6.6.0" !default;
$fa-border-color:             #eee !default;
$fa-inverse:                  #fff !default;
$fa-li-width:                 2em !default;
$fa-fw-width:                 calc(20em / 16);


// Custom iconfont
$custom-iconfont-enabled: 	true !default;
$custom-iconfont-path: 		"/assets/fonts" !default;
$custom-iconfont-name: 		"iconfont" !default;
$custom-iconfont-file: 		"iconfont" !default;
$custom-iconfont-class: 	"fac" !default;
