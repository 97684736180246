.super-debtor-identity-infos li.credit-limit .btn-switch .btn-switch-label {
	color: $btn-switch-label-color-light;
	font-size: $form-label-light-font-size;
}

.scenario-status-popover {
	min-width: 100px;
	max-width: 15rem;
}

.super-debtor-identity-infos .infos-list:not(.invoicing-disabled) li.address-part
{
	border-bottom: none;

	&:nth-child(2n+1)
	{
		border-left: 4px solid #e7eaf3;
	}
}

super-debtor-detail-identity-form .modal-body:not(.invoicing-disabled) .address-part:nth-child(2n)
{
	border-left: 4px solid #e7eaf3;
	margin-left: 0.3rem;
	margin-right: 2.70rem !important;
}

.allianz-trade-details-list {
	max-width: 1200px;
}

.fixed-layout {

	#super-debtor-dunning-timeline-anchor.scrolled-by
	{
		transition: $transition-simple;
		margin-top: 80px;
	}

	.dunning-timeline-actions.mb-2.fixed-block
	{
		position: fixed;
		z-index: 2;
		height: 60px;
		left: 300px;
		top: 140px;
		width: calc(100% - 341px);
		background: $content-block-bg;
		box-shadow: 0px 1px 5px -2px black;
		padding-right: 40px;
	}
}