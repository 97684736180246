/* ========================================================================== */
/* MENU */
/* Navigation menu styles */
/* ========================================================================== */


/* Structure
 * ========================================================================== */
#menu
{
	display: flex;
	flex-direction: column;
	width: $menu-w;
	box-shadow: $menu-box-shadow;
	background: $menu-bg;
	color: $menu-text-color;

	.notif-opened &
	{
		z-index: $menu-notif-open-index;
	}

	.menu-collapsed &
	{
		z-index: $menu-collapsed-index;
	}
}

// avoid z-index flickering during CSS transitions
.menu-collapsed #menu {
  clip-path: polygon(0 0, 60px 0, 60px 100%, 0% 100%);
}

/* Menu header
 * ========================================================================== */
.menu-header
{
	display: flex;
	align-items: center;
	padding: $menu-header-padding;
}

/* menu button burger */
.menu-toggle
{
	@extend .btn-reset;
	width: $menu-icons-size;
	height: $menu-icons-size;

	span
	{
		display: block;
		height: $menu-toggle-bar-size;
		background: $menu-toggle-color;
		transition: $transition-all;
		will-change: width;

		+ span { margin-top: $menu-toggle-bar-spacing; }

		&:nth-child(1)
		{
			transform: rotate($menu-toggle-rotation);
		}

		&:nth-child(2)
		{
			width: calc(100% - #{$menu-toggle-bar-offset});
			opacity: 0;
		}

		&:nth-child(3)
		{
    		margin-top: $menu-toggle-offset;
			transform: rotate(-$menu-toggle-rotation);
		}
	}

	&:hover
	{
		span:nth-child(2)
		{
			width: 100%;
		}
	}

	.menu-collapsed &
	{
		span:nth-child(1)
		{
			transform: rotate(0);
		}

		span:nth-child(2)
		{
			opacity: 1;
		}

		span:nth-child(3)
		{
    		margin-top: $menu-toggle-bar-spacing;
			transform: rotate(0);
		}
	}
}

/* Logo */
.menu-logo
{
	padding: 0 $spacing;
	text-align: right;
	flex: 1;
}

/* First-level links
 * ========================================================================== */
.menu-links
{
	overflow: auto;
	overflow-x: hidden;
	font-size: $menu-font-size;
	height: 100%;

	a
	{
		display: flex;
		align-items: center;
		color: inherit;

		&:hover
		{
			color: $menu-link-hover-text-color;
			text-decoration: none;
		}

		&.active
		{
			color: $menu-link-active-text-color;
		}

		span
		{
			padding: 0 $spacing;
			flex: 1;
		}
	}

	> li
	{
		+ li
		{
			margin: $spacing 0 0;
		}

		> a
		{
			@include text-semi-bold;
			padding: $menu-link-padding;
			text-transform: uppercase;

			&:after
			{
				@include losange($menu-link-carret-size, $main-bg);
				display: block;
				margin-right: -($menu-link-carret-size * .5);
				opacity: 0;
			}
		}

		> .active
		{
			box-shadow: $menu-link-active-border;

			&:after
			{
				opacity: 1;
			}
		}
	}
}

/* Second-level links
 * ========================================================================== */
.submenu-links
{
	max-height: 225px;
	padding: $menu-submenu-padding;
	color: $menu-submenu-text-color;
	font-size: $menu-submenu-font-size;

	transition: max-height .3s ease-in-out;

	a
	{
		padding: $menu-submenu-link-padding;
	}

	.menu-collapsed &
	{
		overflow: hidden;
		max-height: 0;
		transition-timing-function: $transition-fn;
	}
}

.menu-icon
{
	flex-shrink: 0;
	flex-grow: 0;
	font-size: $menu-icons-size;
	width: $menu-icons-w;
	text-align: center;
}

[layout-menu-its] img:first-of-type {
    filter: brightness(10);
}
