@use "sass:color";

$ph-direction:            ltr !default;
$ph-bg:                   #fff !default;
$ph-color:                #ced4da !default;
$ph-border:               1px solid color.scale($ph-bg, $lightness: -10%) !default;
$ph-border-radius:        2px !default;

$ph-cols:                 12 !default;
$ph-cols-remove-odd:      true !default;
$ph-gutter:               30px !default;
$ph-spacer:               15px !default;

$ph-avatar-border-radius: 50% !default;

$ph-animation-duration:   0.8s !default;
