/* ========================================================================== */
/* MODALS */
/* Modals formatting classes */
/* ========================================================================== */


/* Bootstrap overrides
* ========================================================================== */
.modal
{
	&-body 
	{
		white-space: pre-wrap;
	}

	&-footer
	{
		@include media-breakpoint-down(sm)
		{
			.btn
			{
				@include btn-block;
			}
		}
	
		@include media-breakpoint-up(md)
		{
			@include flex-spacebetween(center);
	
			&-reverse
			{
				flex-direction: row-reverse;

				// Easily place margin between footer elements
				// inverse Bootstrap styles
				> :not(:first-child) {
					margin:0;
					margin-right: .25rem;
				}
				> :not(:last-child) {
					margin:0;
					margin-left: .25rem;
				}
			}
		}
	}
}
