
.accounting-document-download .icon-link
{
    max-width: 80%;
    text-overflow: ellipsis;
    overflow: hidden;
}

.accounting-comments-block .form-comment-item .comment-item .row {
    margin-right: 0px;
}

accounting-detail-infos
{
    .related-documents li
    {
        border: none !important; // for nested levels
        display: inline;
        padding: 0;
    }
}

accounting-detail-invoicing
{
    .import-manual-accounting-form-view-mode form
    {
        h2
        {
            margin-bottom: 40px;
        }

        .input-group-append
        {
            display: none;
        }

        .form-group
        {
            border-bottom: 1px solid $input-alt-border-color;
        }

        .form-label
        {
            margin-bottom: 0 !important;
        }

        .form-control:disabled
        {
            background: transparent !important;
            border: none !important;
            opacity: 1 !important;
            padding-left: 0 !important;
        }

        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            /* display: none; <- Crashes Chrome on hover */
            -webkit-appearance: none;
            margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
        }

        input[type=number] {
            -moz-appearance:textfield; /* Firefox */
        }
    }
}
