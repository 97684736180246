/* ========================================================================== */
/* TEXTS */
/* Texts formatting classes */
/* ========================================================================== */


/* Bootstrap overrides
 * ========================================================================== */
.badge
{
	color: $white;
}
 
 
/* Divider
* ========================================================================== */
.text-divider
{
	display: block;
	margin: 25px 0;
	text-align: center;
	font-size: $font-size-sm;
	line-height: 0;
	text-transform: uppercase;
	
	span
	{
		padding: 0 $spacing-xs;
		background: $content-block-bg;
	}
 
	&:before
	{
		@include border-top($gray-200);
		content: '';
		display: block;
	}
 }
 
 /* Tooltip
  * ========================================================================== */
 .tooltip
 {
	@include text-italic;
	animation: fadeInTooltip $transition-speed $transition-fn;
	pointer-events: none; // avoid blinking
 }
 
 
 /* Code
  * ========================================================================== */
 .code
 {
	@include border;
	padding: $spacing-xs;
	white-space: pre-line;
 }
 
.can-break-word
{
	word-break: break-all;
}