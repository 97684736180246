/* thai */
@font-face {
  font-family: 'Niramit';
  font-style: italic;
  font-weight: 500;
  src: url(https://fonts.gstatic.com/s/niramit/v10/I_upMpWdvgLdNxVLXbZiYiu-uLfFVZ8.woff2) format('woff2');
  unicode-range: U+02D7, U+0303, U+0331, U+0E01-0E5B, U+200C-200D, U+25CC;
}
/* vietnamese */
@font-face {
  font-family: 'Niramit';
  font-style: italic;
  font-weight: 500;
  src: url(https://fonts.gstatic.com/s/niramit/v10/I_upMpWdvgLdNxVLXbZiYiu-o7fFVZ8.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Niramit';
  font-style: italic;
  font-weight: 500;
  src: url(https://fonts.gstatic.com/s/niramit/v10/I_upMpWdvgLdNxVLXbZiYiu-orfFVZ8.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Niramit';
  font-style: italic;
  font-weight: 500;
  src: url(https://fonts.gstatic.com/s/niramit/v10/I_upMpWdvgLdNxVLXbZiYiu-rLfF.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* thai */
@font-face {
  font-family: 'Niramit';
  font-style: italic;
  font-weight: 600;
  src: url(https://fonts.gstatic.com/s/niramit/v10/I_upMpWdvgLdNxVLXbZiTiy-uLfFVZ8.woff2) format('woff2');
  unicode-range: U+02D7, U+0303, U+0331, U+0E01-0E5B, U+200C-200D, U+25CC;
}
/* vietnamese */
@font-face {
  font-family: 'Niramit';
  font-style: italic;
  font-weight: 600;
  src: url(https://fonts.gstatic.com/s/niramit/v10/I_upMpWdvgLdNxVLXbZiTiy-o7fFVZ8.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Niramit';
  font-style: italic;
  font-weight: 600;
  src: url(https://fonts.gstatic.com/s/niramit/v10/I_upMpWdvgLdNxVLXbZiTiy-orfFVZ8.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Niramit';
  font-style: italic;
  font-weight: 600;
  src: url(https://fonts.gstatic.com/s/niramit/v10/I_upMpWdvgLdNxVLXbZiTiy-rLfF.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* thai */
@font-face {
  font-family: 'Niramit';
  font-style: normal;
  font-weight: 500;
  src: url(https://fonts.gstatic.com/s/niramit/v10/I_urMpWdvgLdNxVLVUB5hjuOrq_B.woff2) format('woff2');
  unicode-range: U+02D7, U+0303, U+0331, U+0E01-0E5B, U+200C-200D, U+25CC;
}
/* vietnamese */
@font-face {
  font-family: 'Niramit';
  font-style: normal;
  font-weight: 500;
  src: url(https://fonts.gstatic.com/s/niramit/v10/I_urMpWdvgLdNxVLVUB5hiCOrq_B.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Niramit';
  font-style: normal;
  font-weight: 500;
  src: url(https://fonts.gstatic.com/s/niramit/v10/I_urMpWdvgLdNxVLVUB5hiGOrq_B.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Niramit';
  font-style: normal;
  font-weight: 500;
  src: url(https://fonts.gstatic.com/s/niramit/v10/I_urMpWdvgLdNxVLVUB5hi-Org.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* thai */
@font-face {
  font-family: 'Niramit';
  font-style: normal;
  font-weight: 600;
  src: url(https://fonts.gstatic.com/s/niramit/v10/I_urMpWdvgLdNxVLVWx-hjuOrq_B.woff2) format('woff2');
  unicode-range: U+02D7, U+0303, U+0331, U+0E01-0E5B, U+200C-200D, U+25CC;
}
/* vietnamese */
@font-face {
  font-family: 'Niramit';
  font-style: normal;
  font-weight: 600;
  src: url(https://fonts.gstatic.com/s/niramit/v10/I_urMpWdvgLdNxVLVWx-hiCOrq_B.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Niramit';
  font-style: normal;
  font-weight: 600;
  src: url(https://fonts.gstatic.com/s/niramit/v10/I_urMpWdvgLdNxVLVWx-hiGOrq_B.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Niramit';
  font-style: normal;
  font-weight: 600;
  src: url(https://fonts.gstatic.com/s/niramit/v10/I_urMpWdvgLdNxVLVWx-hi-Org.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
