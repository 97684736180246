.team-dashboard-page {

    team-global-kpis {

        .kpi-item {
            text-align: center;

            &:nth-child(1) .fa-info-circle { color: white; }

            &:not(:last-child) {
                margin-right: $spacing-xs;
            }

            &-data {
                height: 100%;
            }
        }
    }

    .graph-container .chart-legend .legend-wrap {
        width: calc(100% - 20px);
    }

    .team-assigned-outstanding-container
    {
        max-width: 49%;

        team-assigned-outstanding {
            .custom-charts-series-vertical-g0 {
                opacity: .5;
            }
        }
    }

    .team-system-outstanding-container
    {
        team-system-outstanding {
            [custom-charts-series-vertical],
            .chart-legend .legend-label-color {
                opacity: .5;
            }
        }
    }
}
