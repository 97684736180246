@use "sass:math";

.ph-item {
    &,
    *,
    ::after,
    ::before {
        box-sizing: border-box;
    }

    position: relative;
    display: flex;
    flex-wrap: wrap;
    padding: $ph-gutter ($ph-gutter * 0.5) ($ph-gutter - $ph-spacer) ($ph-gutter * 0.5);
    margin-bottom: $ph-gutter;
    overflow: hidden;
    direction: $ph-direction;
    background-color: $ph-bg;
    border: $ph-border;
    border-radius: $ph-border-radius;

    &::before {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 50%;
        z-index: 1;
        width: 500%;
        margin-left: -250%;
        pointer-events: none;
        content: " ";
        background: linear-gradient(to right, rgba($ph-bg, 0) 46%, rgba($ph-bg, 0.35) 50%, rgba($ph-bg, 0) 54%) 50% 50%;
        animation: ph-animation $ph-animation-duration linear infinite;
    }

    > * {
        display: flex;
        flex: 1 1 auto;
        flex-flow: column;
        padding-right: ($ph-gutter * 0.5);
        padding-left: ($ph-gutter * 0.5);
        margin-bottom: $ph-spacer;
    }
}

.ph-row {
    display: flex;
    flex-wrap: wrap;
    margin-top: -($ph-spacer * 0.5);

    div {
        height: 10px;
        margin-top: ($ph-spacer * 0.5);
        background-color: $ph-color;
    }

    .big,
    &.big div {
        height: 20px;
    }

    .empty {
        background-color: rgba($ph-bg, 0);
    }
}

@mixin ph-make-col($size, $columns) {
    .ph-col-#{$size} {
        flex: 0 0 math.percentage(math.div($size, $columns));
    }
}

@for $i from 1 through $ph-cols {
    @if $ph-cols-remove-odd and $i % 2 == 0 {
        @include ph-make-col($i, $ph-cols);
    }

    @else if not $ph-cols-remove-odd {
        @include ph-make-col($i, $ph-cols);
    }
}

// TODO make this a `.ph-wrap` class for no padding, no background, no animation,
// and remove direction from `.ph-item`. This is a breaking change
[class*="ph-col"] {
    direction: $ph-direction;

    > * {
        + .ph-row {
            margin-top: 0;
        }

        + * {
            margin-top: ($ph-spacer * 0.5);
        }
    }
}

.ph-avatar {
    position: relative;
    width: 100%;
    min-width: 60px;
    overflow: hidden;
    background-color: $ph-color;
    border-radius: $ph-avatar-border-radius;

    &::before {
        display: block;
        padding-top: 100%;
        content: " ";
    }
}

.ph-picture {
    width: 100%;
    height: 120px;
    background-color: $ph-color;
}

@keyframes ph-animation {
    0% {
        transform: translate3d(-30%, 0, 0);
    }

    100% {
        transform: translate3d(30%, 0, 0);
    }
}
