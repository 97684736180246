/* ========================================================================== */
/* ALERTS */
/* Alerts component styles */
/* ========================================================================== */


/* Bootstrap overrides
 * ========================================================================== */
.alert
{
    .bullet-list {
        list-style-type: disc !important;
    }
    
}