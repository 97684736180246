/* ========================================================================== */
/* BUTTONS */
/* Buttons component styles */
/* ========================================================================== */


/* Bootstrap overrides
 * ========================================================================== */
.btn
{
	font-size: $btn-font-size;
	text-transform: uppercase;

	&-secondary
	{
		&, &[disabled] { color: $white; }
	}

	&-default
	{
		&, &[disabled] { color: $gray-800; }
		
		&:hover,
		&:focus,
		&:not(:disabled):not(:disabled):active { color: $gray-900; } // wierd bootstrap override
	}

	&-transparent
	{
		&, &[disabled] { color: $link-color; }
		
		&:hover,
		&:focus,
		&:not(:disabled):not(:disabled):active { color: $link-hover-color; } // wierd bootstrap override
	}

	&[disabled] {
		pointer-events: all !important;
		cursor: not-allowed;
	}

	&-badged
	{
		position: relative;

		.badge
		{
			@include position-absolute(0, 0);
			transform: translate(50%, -50%);
		}
	}

	&-thumbtack {
		font-size: $btn-thumbtack-font-size;
	}
}


/* Add
 * ========================================================================== */
.btn-reset
{
	background: 0;
	border: 0;
	padding: 0;

	&:focus
	{
		outline: 0;
	}
}

.btn-filter
{
	@include no-input;
	margin-left: $spacing-xs;
	padding: $btn-filter-padding;
	border-radius: $btn-filter-radius;
	background: $white;
	color: $gray-450;
	line-height: 1;
	text-transform: uppercase;

	&.active
	{
		background: $btn-filter-active-bg;
		color: $white;
	}
}

.btn-link 
{
	@extend %icon-link;
	text-transform: none;
	
	&-gray
	{
		color: #666;
	}

	&:not(.not-selected, .no-arrow):before
	{
        @extend %fa-icon;
        @extend .fal;
        @include text-semi-bold;
        margin-right: 5px;
        content: fa-content($fa-var-angle-right);
	}

	&-more:before
	{
        content: fa-content($fa-var-angle-down);
	}

	&-less:before
	{
        content: fa-content($fa-var-angle-up);
	}
}

.btn-a
{
	@extend .btn-reset;
	color: $link-color;
	text-decoration: $link-decoration;
	
	&:hover,
	&:focus
	{
		color: $link-hover-color;
		text-decoration: $link-hover-decoration;
	}
}

.btn-pointer {
	cursor: pointer;
}

.btn-link-filter
{
	@extend .btn-reset;
	@include link-underline;
	color: $btn-link-color;
	font-size: $btn-link-font-size;
	text-transform: none;
}

.btn-switch
{
	$context: &;

	@include no-input;
	display: inline-flex;
	align-items: center;
	vertical-align: middle;
	color: $btn-switch-label-color;
	font-size: $form-label-font-size;
	text-transform: uppercase;
	
	input.checked + #{$context}-handle
	{
		background: $btn-switch-checked-bg-color;
		&:before { transform: translateX($btn-switch-toggle-translateX); }
	}

	&-handle
	{
		@include rectangle($btn-switch-width, $btn-switch-height);
		@include align-inline-block;
		position: relative;
		border-radius: $btn-switch-border-radius;
		background: $btn-switch-bg-color;
		transition: $transition-all;
		cursor: pointer;
		margin-right: 5px;

		&:before
		{
			@include circle($btn-switch-toggle-size);
			@include position-absolute($btn-switch-toggle-position, null, null, $btn-switch-toggle-position);
			content: '';
			background: $btn-switch-toggle-color;
			transition: $transition-all;
		}
	} 

	&.is-revert
	{
		flex-direction: row-reverse;

		#{$context}-handle
		{
			margin-right: 0;
			margin-left: 5px;
		}
	}

	&.is-invalid
	{
		color: $form-feedback-invalid-color;
	}
	
	&.is-disabled
	{
		opacity: $btn-disabled-opacity;

		.btn-switch-handle
		{
			cursor: not-allowed;
		}
	}
}

.btn-switch-pending .btn-switch input.checked + .btn-switch-handle
{
	background: $btn-switch-pending-bg-color;
}

.btn-icon-rounded
{
	@extend .btn-reset;
	@include circle(18px);
	@include text-bold;
	display: inline-flex;
	background: $gray-300;
	color: $white;
	font-size: $font-size-sm;
}

.btn-delete
{
	@extend .btn-icon-rounded;
	margin-left: 5px;
}

.validation-select
{
	display: inline-block;
}

a[mailto]
{
	@extend .arrow-link;
	cursor: pointer;
}

button.badge
{
	border: 0;
	outline: 0;
}

.btn .loader.loader-small {
	margin-left: $spacing-xs;

	.loader-spinner {
		margin: 0;
	}
}

// Documents links
kyc-document-link,
invoice-document-link
{
	cursor: pointer;
}
