/* ========================================================================== */
/* LAYOUT VARS */
/* Layout's main sections vars */
/* ========================================================================== */

/* Menu
 * ========================================================================== */
$menu-bg: 							$primary !default;
$menu-w: 							260px !default;
$menu-font-size: 					$font-size-base !default;
$menu-text-color: 					$secondary-light !default;

$menu-link-padding: 				$spacing-xs 0 !default;
$menu-link-active-text-color: 		$white !default;
$menu-link-hover-text-color: 		$white !default;
$menu-link-active-border: 			3px 0 0 0 $white inset !default;
$menu-link-carret-size:         	7px !default;

$menu-icons-w: 						60px !default;
$menu-icons-size: 					20px !default;
$menu-box-shadow: 					$menu-icons-w 0 0 rgba($black, .2) inset, -2px 0 0 rgba($black, .2) inset !default;

$menu-header-padding: 				$spacing 0 !default;

$menu-toggle-color: 				$white !default;		
$menu-toggle-transition: 			width $transition-speed $transition-fn !default;		
$menu-toggle-bar-size: 				2px !default;		
$menu-toggle-bar-spacing: 			4px !default;		
$menu-toggle-bar-offset: 			3px !default;	
$menu-toggle-rotation:				45deg !default;
$menu-toggle-offset:				-8px !default;	

$menu-submenu-padding: 				0 0 0 $menu-icons-w  !default;
$menu-submenu-link-padding: 		5px $spacing !default;
$menu-submenu-font-size: 			px-to-rem(13px) !default;
$menu-submenu-text-color: 			$secondary !default;

$menu-hidden-w:             		$menu-w - $menu-icons-w !default;
$menu-notif-open-index:				3 !default;
$menu-collapsed-index:				1 !default;

/* Content
 * ========================================================================== */
$content-transition: 				margin-left $transition-speed $transition-fn !default;

$content-authentication-w: 			360px !default;
$content-authentication-padding: 	$spacing-lg !default;

$content-block-shadow:				0 1px 4px 0 rgba($black, 0.08) !default;
$content-block-bg:					$white !default;
$content-block-padding:				$spacing-md !default;
$content-block-sidebar-w:			310px !default;
$content-block-border:				1px solid $gray-550 !default;
$content-block-negative-margin:		0 (-$content-block-padding) !default;

/* Top Bar
 * ========================================================================== */
$top-bar-bg: 						$white !default;
$top-bar-h: 						70px !default;
$top-bar-padding: 					0 $spacing-md !default;

$search-form-w: 					400px !default;

$top-bar-notification-size:			$menu-icons-size !default;
$top-bar-notification-bullet-color: $yellow !default;
$top-bar-notification-bg-open:		#f4f3f3 !default;
$top-bar-notif-circle-size:			40px !default;
$top-bar-notif-bullet-size:			7px !default;
$top-bar-notif-bullet-y:			9px !default;
$top-bar-notif-bullet-x:			11px !default;
$top-bar-notif-triangle-x:			15px !default;
$top-bar-notif-triangle-y:			-19px !default;

/* Main
 * ========================================================================== */
$main-bg:							$body-bg !default;
$main-padding-x:					$spacing-md !default;
$main-padding-y:					$spacing !default;
$main-padding:						$main-padding-y $main-padding-x !default;
$main-border:						1px solid $gray-200 !default;

$main-notification-bg:				#adadad !default;
$main-notification-opacity:			.3 !default;
$content-loader-bg:                 #fff !default;
$content-loader-el-bg:              #ced4da !default;

/* Breadcrumb
 * ========================================================================== */
$breadcrumb-font-size:  			px-to-rem(13px) !default;
$breadcrumb-color:      			$gray-600 !default;
$breadcrumb-control-page-width: 	ratio-to-percentage(0.6666) !default; 

/* Page header
 * ========================================================================== */
$page-header-dropdown-w: 			230px !default;
$tenant-title-color:                $gray-600 !default;

/* Taskboard
 * ========================================================================== */
$taskboard-col-padding:				50px !default;
$taskboard-inter-border-x:			$spacing-md !default;
$taskboard-inter-border-width:		$border-width !default;
$taskboard-border-color:			$gray-550 !default;

$tasks-group-title-color:			$secondary !default;
$tasks-group-title-size:			$font-size-md !default;


/* Control structure */
/* ========================================================================== */
$content-control-bg:				$white !default;;
$content-control-sidebar-margin:	auto -40px -20px -40px !default;
$content-control-sidebar-offset-x:	-55px !default;

$content-control-main-xl-width:		ratio-to-percentage(0.6666) !default;
$content-control-sidebar-xl-width:	calc(#{ratio-to-percentage(0.3333)} - #{$spacing-md}) !default;

$content-control-sidebar-xl-limit: 	#{$content-control-main-xl-width} + #{$spacing} !default;
$content-control-sidebar-xl-bg:		linear-gradient(
										to right,
										$body-bg calc(#{$content-control-sidebar-xl-limit}) ,
										$gray-550 calc(#{$content-control-sidebar-xl-limit}),
										$gray-550 calc(#{$content-control-sidebar-xl-limit} + 1px),
										$content-control-bg calc(#{$content-control-sidebar-xl-limit} + 1px)
									) !default;


/* Page anchors
 * ========================================================================== */
$page-anchors-bg: 					$primary-light !default;
$page-anchors-hover-bg: 			$primary !default;
$page-anchors-border: 				$primary !default;
$page-anchors-color: 				$white !default;
$page-anchors-btn-size: 			50px !default;
$page-anchors-icon-size: 			20px !default;

/* Charts
 * ========================================================================== */
$charts-tick-color: 				black !default;
$charts-legend-color: 				black !default;

/* Page loaders
 * ========================================================================== */
$loader-block-bg: rgba(255, 255, 255, 0.8) !default;

/* Collapsible
 * ========================================================================== */
$subtitle-spacing: 21px !default;