/**
 * From https://stackoverflow.com/a/58570835/1689894
 */

.styled-checkbox input[type="checkbox"] {
    position: absolute;
    opacity: 0;
    z-index: -1;
}

/* Text color for the label */
.styled-checkbox input[type="checkbox"] + span {
    cursor: pointer;
    font: 14px sans-serif;
    color: $success;
    vertical-align: middle;
}

/* Checkbox un-checked style */
.styled-checkbox input[type="checkbox"] + span:before {
    content: '';
    border: 1px solid $success;
    border-radius: 3px;
    display: inline-block;
    width: 14px;
    height: 14px;
    margin-right: 0.5em;
    margin-top: 0.5em;
    vertical-align: -2px;
}

/* Checked checkbox style (in this case the background is green #e7ffba, change this to change the color) */
.styled-checkbox input[type="checkbox"]:checked + span:before {
    font-family: 'Font Awesome 5 Pro';
    content: '\f00c';
    color: black;
    background: $success;
    font-size: 13px;
    line-height: 13px;
    vertical-align: baseline;
}

/* Adding a dotted border around the active tabbed-into checkbox */
.styled-checkbox input[type="checkbox"]:focus + span:before,
.styled-checkbox input[type="checkbox"]:not(:disabled) + span:hover:before {
    /* Visible in the full-color space */
    box-shadow: 0px 0px 0px 2px $success;

    /* Visible in Windows high-contrast themes
        box-shadow will be hidden in these modes and
        transparency will not be hidden in high-contrast
        thus box-shadow will not show but the outline will
        providing accessibility */
    outline-color: transparent; /*switch to transparent*/
    outline-width: 2px;
    outline-style: dotted;
}


/* Disabled checkbox styles */
.styled-checkbox input[type="checkbox"]:disabled + span {
    cursor: default;
    color: black;
    opacity: 0.5;
}
