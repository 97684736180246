.col-dso {
	text-align: center;
	padding-left: 0 !important;
	padding-right: 0 !important;
	font-weight: 600;
	i {
		margin-left: 5px;
		font-size: 16px;
	}
}

.dso-color {

	.orange-light {
		color: $orange-light;
	}
	.orange {
		color: orange;
	}
	.green {
		color: green;
	}
	.red {
		color: red;
	}
}