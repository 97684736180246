status-timeline {
    display: block; // for ResizeObserver
}

.status-timeline {
    &-wrapper {
        height: 60px;
        padding: 0 10px;
    }

    &-item {
        width: 100%;
        min-width: 10px; // sync with status-timeline.component
        max-width: 30px;
        margin: 5px 2px 0 2px;
        border-top-left-radius: 2px;
        border-top-right-radius: 2px;
    }
}