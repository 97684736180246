/* ========================================================================== */
/* ALERTS */
/* Alerts component styles */
/* ========================================================================== */


/* Bootstrap overrides
 * ========================================================================== */
.progress
{
    &-large {
        height: 2em;
    }
}
 