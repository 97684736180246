/* ========================================================================== */
/* STATUS */
/* Status component styles */
/* ========================================================================== */


.status-info
{
	@include text-italic;
	display: flex;
	align-items: center;
	font-size: $status-font-size;
	white-space: nowrap;

	h1 &
	{
		// display: inline-flex;
		font-size: $font-size-md;
		transform: translateY(-5px);
	}

	&:before
	{
		@include bullet;
	}

	@each $type, $color in $status-colors
	{
		&-#{$type}
		{
			color: $color;

			&:before			
			{
				background: $color;
			}
		}
	}	

	&-progress,
	&-duration
	{
		margin-left: 3px;
	}

	&-duration
	{
		font-size: $font-size-sm;
		color: $body-color;
	}
}