/* ========================================================================== */
/* FONTS */
/* Fonts imports */
/* ========================================================================== */

@each $path in $font-family-import-paths
{
	@import url('' + $path);
}

@if ($custom-iconfont-enabled == true)
{
	@font-face
	{
		font-family: $custom-iconfont-name;
		font-style: normal;
		font-weight: normal;
		src: url('#{$custom-iconfont-path}/#{$custom-iconfont-file}.eot');
		src: url('#{$custom-iconfont-path}/#{$custom-iconfont-file}.eot?#iefix') format('embedded-opentype'),
		url('#{$custom-iconfont-path}/#{$custom-iconfont-file}.woff2') format('woff2'),
		url('#{$custom-iconfont-path}/#{$custom-iconfont-file}.woff') format('woff'),
		url('#{$custom-iconfont-path}/#{$custom-iconfont-file}.ttf') format('truetype'),
		url('#{$custom-iconfont-path}/#{$custom-iconfont-file}.svg##{$custom-iconfont-name}') format('svg');
	}
}
