/* ========================================================================== */
/* KYC VARS */
/* Kyc vars */
/* ========================================================================== */


/* kyc lcbft seller
* ========================================================================== */
$kyc-lcbft-item-color:					$gray-700 !default;
$kyc-lcbft-item-margin:					$spacing 0 0 !default;
$kyc-lcbft-item-padding:				$spacing 30px !default;
$kyc-lcbft-label-color:					$gray-450 !default;


/* kyc create seller
* ========================================================================== */
$kyc-seller-padding:					$checkbox-radio-label-offset !default;
$kyc-seller-color:						$gray-700 !default;
$kyc-seller-font:						$font-size-sm !default;


/* kyc create alerts
* ========================================================================== */
$kyc-alert-icon-size:					25px !default;