
.variation {
    opacity: .7;
    font-size: 3em;
    line-height: 1em;

    .fa-long-arrow-up {
        transform: rotate(45deg);
        margin-left: 5px;
    }

    .fa-long-arrow-down {
        transform: rotate(-45deg);
        margin-left: 5px;
    }

    &-good {
        color: $green;
    }

    &-egal {
        color: $white;
    }

    &-bad {
        color: $orange-dark;
    }
}
