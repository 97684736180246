/* ========================================================================== */
/* CHECKBOX AND RADIO BUTTONS CUSTOM */
/* Checkbox and radio styles */
/* ========================================================================== */

/* Checkbox and radio custom
 * ========================================================================== */
 .form-check, .form-radio
{
	padding-left: 0;
	margin: 0 0 1rem;

	&-input
	{
		display: none;
	}

	&-label
	{
		cursor: pointer;
		
		&:before
		{
			@include flex-center(null, inline-flex);
			content: '';
			margin-right: 5px;
			vertical-align: middle;
		}
	}
}
