/* ========================================================================== */
/* IMPORT MANUAL PAGES */
/* Import manual pages specific styles  */
/* ========================================================================== */

import-manual-super-debtor-form section:not(.with-manual-invoicing)
{
	collapsible-block.content-block-low-margin
	{
		display: block;
	}

	.import-manual-super-debtor-form-content {
    margin-left: 50%;
    display: flex;
    width: 50%;

		.col-md-3
		{
			flex: 0 0 50%;
			max-width: 50%;
		}
	}
}

import-manual-super-debtor-form section.with-manual-invoicing
{
	div.col-md-3.d-flex.justify-content-center.align-items-center
	{
			flex: 0 0 100%;
			max-width: 100%;
			justify-content: start !important;
			margin-left: 1%;
			margin-bottom: 15px;
	}

	collapsible-block
	{
		div.d-flex.import-manual-super-debtor-form.justify-content-end
		{
			margin-right: 50.4%;
		}

		div.col-md-3.d-flex
		{
			flex: 0 0 100%;
			max-width: 100%;
			margin-top: 10px;
			margin-bottom: 25px;
		}
	}

}

import-manual-accounting-form
{
  .import-manual-line-items-container
  {
    overflow-x: auto;
  }
}
