/* ========================================================================== */
/* SELLERS VARS */
/* Seller vars */
/* ========================================================================== */

/* Status */
/* ========================================================================== */
$status-font-size:		px-to-rem(13px) !default;
$status-colors:	    	(
							pending: $orange,
							confirmed: $secondary,
							ready: $secondary,
							active: $green,
							validated: $green,
							inactive: $gray-600,
							skipped: $green-light,
							blocked: $red,
							refused: $red,
							closed: $gray-800
						) !default;
