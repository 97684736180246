/* ========================================================================== */
/* TABLES */
/* Tables component styles */
/* ========================================================================== */


/* Bootstrap overrides
 * ========================================================================== */
.table
{
	margin-bottom: 0;

	thead
	{
		text-transform: uppercase;

		th
		{
			@include text-normal;
			border-bottom: 0;
			vertical-align: middle;
		}
	}

	tbody
	{
		> tr:last-child
		{
			td
			{
				border-bottom: $table-border-style;
			}
		}
	}

	&-footer-totals
	{
		font-size: $font-size-lg;
		background: $table-foot-bg;

		.label
		{
			font-size: $font-size-base;
		}
	}

	&-footer-totals-light
	{
		font-size: $font-size-md;
		background: $table-foot-bg;

		.label
		{
			font-size: $font-size-base;
		}

		&-balance
		{
			color: $primary;
		}
	}

	&-footer-totals-light-fat-borders tr
	{
		border-top: 10px transparent solid;

		.table-footer-totals-label
		{
			color: $table-light-thead-color;
			text-transform: uppercase;
			padding: $table-light-thead-padding;	
			background-color: $table-head-bg !important;
		}
	}

	&-active
	{
		td
		{
			border-top: $table-active-border;
		}

		& + tr
		{
			td { border-top: 0; }
		}
	}

	a
	{
		@include text-semi-bold;
	}

	@include media-breakpoint-down(lg)
	{
		th, td { padding: $table-cell-padding-sm }
	}
}

.table-extra-data
{
	@extend .active-filters-wrap;
}


/* Responsive
 * ========================================================================== */
.table-responsive
{
	// HACK: Prevent scrollbar to be visible all the time when a row is expanded (100% makes the table overflow by 1px)
	> table,
	> .table { width: calc(100% - 1px); }
}


/* Sortable table
 * ========================================================================== */
.table-sortable
{
}

.is-sortable
{
	position: relative;
	cursor: pointer;

	&:before,
	&:after
	{
		@extend %fa-icon;
		@extend .fal;
		@include pos-center-y;
		@include text-semi-bold;
		right: 5px;
		opacity: $table-sort-icon-opacity;
	}

	&:before
	{
		content: fa-content($fa-var-angle-up);
		margin-top: -4px;
	}

	&:after
	{
		content: fa-content($fa-var-angle-down);
		margin-top: 4px;
	}
}

.is-sorted-asc:before,
.is-sorted-desc:after
{
	opacity: 1;
}

/* Collapsible table
 * ========================================================================== */
.table-collapsible
{
	cursor: pointer;

	&-content
	{
		> td
		{
			padding: 0;
			border-top: 0;
		}
	}

	&-content-meta
	{
		background-color: $table-head-bg;
	}
}


/* Draggable table
 * ========================================================================== */
.table-draggable
{
	cursor: grab;

	&.dndDragging
	{
		cursor: grabbing;

		&Source
		{
			display: none;
		}
	}

	&-placeholder
	{
		td
		{
			background: $table-dragging-placeholder-bg;
		}
	}
}

/* Table with a fixed scrollable wrapper
 * ========================================================================== */
.table-fixed-height-wrapper {
	height: 600px;
	overflow: auto;

	&-maxed {
		height: auto;
		max-height: 600px;
	}

	/* Set header to stick to the top of the container. */
  	thead tr th {
		position: sticky;
		top: 0;
  	}

	// Because we must set sticky on th,
	// we have to apply background styles here
	// rather than on tfoot
	tfoot tr {
		td, th {
			background: $table-head-bg;
			position: sticky;
			border-top: 0;
			bottom: 0;
		}
	}
	tfoot tr.one-row-above {
		td, th {
			bottom: 55px;
		}
		& + tr {
			height: 55px;
		}
	}

	// Because we must set sticky on th,
	// we have to apply background styles here
	// rather than on thead
	th {
		// avoid above-thead artifacts
		box-shadow: 1px 0px 0 0px $table-head-bg;
	}
}

/* Table's columns
 * ========================================================================== */
.col-nowrap
{
	white-space: nowrap;
}

.col-total {
	text-align: end;
    padding-left: 0px !important;
    padding-right: 50px !important;
}

@each $col, $infos in $table-col-sizes
{
	.col-#{$col}
	{
		width: nth($infos, 1);
		min-width: nth($infos, 1);
		@if nth($infos, 3) == true { @extend .col-nowrap; }

		// For css specificity
		tbody &
		{
			text-align: nth($infos, 2);
			vertical-align: nth($infos, 4);
		}
	}
}

thead .col-checkbox {
    text-align: center;
}

.col-small-comment {
	text-align: center;
}

.is-pending
{
	@include text-italic;
}

th.col-index
{
	text-align: center;
}

.col-name
{
	button {
		text-align: start;
	}
}

.col-btn-expand,
.col-btn-actions,
.col-order,
.col-file
{

	color: $gray-600;

	i
	{
		font-size: $font-size-lg;
	}
}

.col-date
{
	white-space: nowrap;
}

.col-subheader
{
	border-bottom-width: 1px;
	border-bottom-style: groove;
}

.col-faded
{
	opacity: 0.5;
}

.col-checkbox,
.col-btn-actions
{
	+ th, + td { padding-left: 0; }
}

td.col-btn-actions
{
	padding-left: 0;

	& + td.col-btn-expand,
	&:not(:last-child) { padding-right: 0; }
}

.col-btn-expand
{
	i
	{
		.table-active &:before
		{
			content: fa-content($fa-var-angle-up);
		}
	}
}

td.col-status
{
	@include text-italic;
}

/* Nested table
 * ========================================================================== */
.table-nested-wrapper
{
	@include toggle-animation('&.is-open', $table-nested-transition-height, 1, $table-nested-transition-close, $table-nested-transition-open);
	overflow: hidden;

	&.is-open
	{
		overflow-y: auto;
	}
}

.table-nested
{
	thead
	{
		@include border-bottom($table-border-color, $table-border-width);
	}

	tbody
	{
		tr
		{
			td
			{
				border: 0;
				background: $table-nested-bg-color;
			}

			&:last-child td { border: 0; }
		}
	}
}



/* Custom tables
 * ========================================================================== */
.table-light
{
	background: $table-light-bg;
	font-size: $table-light-font-size;
    border-collapse: separate;
	border-spacing: $table-light-spacing;
	width: $table-light-width;
	margin: 0 0 $spacing-md;

	thead
	{
		color: $table-light-thead-color;
		text-transform: uppercase;

		th
		{
			padding: $table-light-thead-padding;
		}
	}

	tbody
	{
		color: $table-light-tbody-color;

		td
		{
			@include border-top($table-light-border-color);
			@include border-bottom($table-light-border-color, 2px);
			padding: $table-light-tbody-padding;

			&:first-child
			{
				@include border-left($table-light-border-color);
				border-radius: $border-radius 0 0 $border-radius;
			}

			&:last-child
			{
				@include border-right($table-light-border-color);
				border-radius: 0 $border-radius $border-radius 0;
			}
		}
	}

	&-with-validation-btns {
		overflow: hidden;
		padding-bottom: 50px;
		margin-bottom: 0;
	}

	a
	{
		@extend .arrow-link;
	}

	.btn-link
	{
		font-size: $table-flex-font-size;
	}
}

// Override Bottstrap default
// to make table headcells relative
// to fix Bug 25927: Acc [Mo-import-Données] Il manque 2 petits traits bleu sur certaines colonnes de la première erreur expanded
.thead-light {
    th {
		position: relative;
    }
}

/* Nested table
 * ========================================================================== */
.table-spreadsheet
{
	th, td
	{
		padding: $table-cell-padding-sm;
	}

	th
	{
		background: $table-spreadsheet-th-bg;
		border: $table-spreadsheet-th-border;

		&:first-child { border-left: 0; }
		&:last-child { border-right: 0; }

		.form-control
		{
			margin-top: 5px;
		}

		&.col-mapping-done
		{
			background: $table-spreadsheet-th-bg-valid;
		}
	}

	td
	{
		background: $table-spreadsheet-td-bg;
		border: $table-spreadsheet-td-border;
		&:last-child { border-right: 0; }
	}

	tbody
	{
		tr
		{
			&:first-child
			{
				th, td { border-top: $table-spreadsheet-th-border; }
			}

			&:last-child
			{
				th, td { border-bottom: $table-spreadsheet-th-border; }
			}

			&:nth-child(#{$table-striped-order})
			{
				td
				{
					background: $table-spreadsheet-td-accent-bg;
				}
			}
		}

	}
}

.table-sub-action{
	margin-top: -40px;
	margin-bottom: 40px;
}

/* Table with selection
 * ========================================================================== */
 .table-selection-pending *::selection {
	background: transparent;
}