/* Information about the app version
 * ========================================================================== */
.version-info-container
{
	position: fixed;
	bottom: 0;
	right: $spacing-xs;
	z-index: 2;
	padding: $spacing-xs;
	background: $gray-200;
	font-size: $font-size-md;
	width: calc(100% - #{$menu-w} - 10px);

	.feature-flags-menu
	{
		max-height: 800px;
		overflow: scroll;
	}
}
