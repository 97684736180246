.small-contact-card {
    $context: &;

    padding: $spacing-xs;
    margin-right: $spacing;
    border: $card-item-border;
    border-radius: $card-item-border-radius;

    &-header
    {
        color: $primary;
    }

    &-actions
    {
        label
        {
            color: $success;
        }
    }

    &-content
    {
        margin-top: $spacing-xs;
        min-height: 50px;
    }

    .card-contact-content-item
    {
        margin-bottom: 0;
    }

    label.styled-checkbox.success {
        color: $success;
        & input[type="checkbox"] + span {
            color: $success;
        }
        & input[type="checkbox"] + span::before {
            border-color: $success;
        }
        & input[type="checkbox"]:focus + span::before,
        & input[type="checkbox"]:not(:disabled) + span:hover::before {
            box-shadow: 0px 0px 0px 2px $success;
        }
        & input[type="checkbox"]:checked + span::before {
            background: $success;
        }
    }

    label.styled-checkbox.warning {
        color: $warning;
        & input[type="checkbox"] + span {
            color: $orange;
        }
        & input[type="checkbox"] + span::before {
            border-color: $orange;
        }
        & input[type="checkbox"]:focus + span::before,
        & input[type="checkbox"]:not(:disabled) + span:hover::before {
            box-shadow: 0px 0px 0px 2px $orange;
        }
        & input[type="checkbox"]:checked + span::before {
            background: $orange;
        }
    }

    label.styled-checkbox.danger {
        color: $danger;
        & input[type="checkbox"] + span {
            color: $red;
        }
        & input[type="checkbox"] + span::before {
            border-color: $red;
        }
        & input[type="checkbox"]:focus + span::before,
        & input[type="checkbox"]:not(:disabled) + span:hover::before {
            box-shadow: 0px 0px 0px 2px $red;
        }
        & input[type="checkbox"]:checked + span::before {
            background: $red;
        }
    }
}
