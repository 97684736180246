/* ========================================================================== */
/* TASKS */
/* Tasks component styles */
/* ========================================================================== */

/* Task item
 * ========================================================================== */
task-item:last-child .task-item { margin-bottom: 0; }

.task-item
{
	background: $task-item-bg-color;
	cursor: pointer;
	position: relative;
	padding-left: $task-item-padding-left;
	margin: $spacing-xs 0;

	&-new
	{
		&:before { background: $task-status-new-color }
	}

	&-in-progress
	{
		&:before { background: $task-status-progress-color }
	}

	&-done
	{
		background: $task-item-disabled-bg-color;
		&:before { background: $task-status-done-color }
	}

	&-none
	{
		@include text-italic;
		color: $task-item-body-color;
	}

	&:before
	{
		@include position-absolute($task-item-bar-position, null, $task-item-bar-position, $task-item-bar-position);
		content: '';
		width: $task-item-bar-size;
		border-radius: $border-radius;
	}

	&-head
	{
		@include flex-spacebetween(center);
		@include text-semi-bold;
		margin-bottom: 5px;
	}

	&-type
	{
		margin-left: auto;
	}

	.name
	{
	}

	.other-name
	{
		color: $gray-500;
	}

	.badge
	{
		margin-left: $spacing-xs;
	}
}
