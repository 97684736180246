/* ========================================================================== */
/* ANIMATIONS */
/* CSS3 animations definitions */
/* ========================================================================== */

/* Rotate
 * ========================================================================== */
@keyframes rotate {
	100% {
		transform: rotate(360deg);
	}
}

/* Dash (SVG properties)
 * ========================================================================== */
@keyframes dash {
	0% {
		stroke-dasharray: 1, 150;
		stroke-dashoffset: 0;
	}

	50% {
		stroke-dasharray: 90, 150;
		stroke-dashoffset: -35;
	}

	100% {
		stroke-dasharray: 90, 150;
		stroke-dashoffset: -124;
	}
}


/* FadeIn for Tooltip
 * ========================================================================== */
@keyframes fadeInTooltip {
	0% {
		opacity: 0;
	}

	100% {
		opacity: $tooltip-opacity;
	}
}


/* Underline
 * ========================================================================== */
 .effect-underline:after {
	content: '';
	position: absolute;
	left: 0;
	display: inline-block;
	height: 1em;
	width: 100%;
	border-bottom: 1px solid;
	margin-top: 10px;
	opacity: 0;
	transition: opacity 0.35s, transform 0.35s;
	transform: scale(0, 1);
}

.effect-underline:hover:after {
	opacity: 1;
	transform: scale(1);
}

/* Box
 * ========================================================================== */
 .effect-box:after,
.effect-box:before {
	content: '';
	position: absolute;
	left: 0;
	display: inline-block;
	height: 1em;
	width: 100%;
	margin-top: 10px;
	opacity: 0;
	transition: opacity 0.35s, transform 0.35s;

}

.effect-box:before {
	border-left: 1px solid;
	border-right: 1px solid;
	transform: scale(1, 0);
}

.effect-box:after {
	border-bottom: 1px solid;
	border-top: 1px solid;
	transform: scale(0, 1);
}

.effect-box:hover:after,
.effect-box:hover:before {
	opacity: 1;
	transform: scale(1);
}

/* Shine
 * ========================================================================== */
 @keyframes shine {
	from {
		mask-position: 150%;
	}

	to {
		mask-position: -50%;
	}
}
.effect-shine {
	mask-image: linear-gradient(-75deg, rgba(0, 0, 0, .6) 30%, #000 50%, rgba(0, 0, 0, .6) 70%);
	mask-size: 200%;
	animation: shine 2s infinite;
}
.effect-shine-hover:hover {
	mask-image: linear-gradient(-75deg, rgba(0, 0, 0, .6) 30%, #000 50%, rgba(0, 0, 0, .6) 70%);
	mask-size: 200%;
	animation: shine 2s infinite;
}
