/*!
 * Bootstrap v4.1.3 (https://getbootstrap.com/)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */


@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/root";
@import "bootstrap/scss/reboot";
@import "bootstrap/scss/type";
//@import "bootstrap/scss/images";
//@import "bootstrap/scss/code";
@import "bootstrap/scss/grid";
@import "bootstrap/scss/tables";
@import "bootstrap/scss/forms";
@import "bootstrap/scss/buttons";
@import "bootstrap/scss/transitions";
@import "bootstrap/scss/dropdown";
@import "bootstrap/scss/button-group";
@import "bootstrap/scss/input-group";
//@import "bootstrap/scss/custom-forms";
//@import "bootstrap/scss/nav";
//@import "bootstrap/scss/navbar";
//@import "bootstrap/scss/card";
@import "bootstrap/scss/breadcrumb";
@import "bootstrap/scss/pagination";
@import "bootstrap/scss/badge";
//@import "bootstrap/scss/jumbotron";
@import "bootstrap/scss/alert";
@import "bootstrap/scss/progress";
//@import "bootstrap/scss/media";
//@import "bootstrap/scss/list-group";
@import "bootstrap/scss/close";
@import "bootstrap/scss/modal";
@import "bootstrap/scss/tooltip";
@import "bootstrap/scss/popover";
@import "bootstrap/scss/carousel";
@import "bootstrap/scss/utilities";
//@import "bootstrap/scss/print";

// TODO: Remove once upgraded to Bootstrap 5
.bg-opacity-75 {
  opacity: 0.75;
}
.bg-opacity-50 {
  opacity: 0.50;
}

.bg-light {
  background-color: $gray-100 !important;
}

// Custom sizing
.h-30px {
	height: 30px;
}

// Custom spacing
.mb-10px {
	margin-bottom: 10px;
}

.font-style-normal {
  font-style: normal !important;
}

/* Bootstrap overrides */
bs-datepicker-container, bs-daterangepicker-container {
  z-index: 10000 !important // over modals and offcanvas
}

ngb-modal-backdrop.modal-backdrop.fade.show, ngb-modal-window {
  z-index: 10000 !important // over modals and offcanvas
}

