/* ========================================================================== */
/* AUTHENTICATION */
/* Authentication pages styles */
/* ========================================================================== */

/* Common styles
 * ========================================================================== */
.authentication-layout
{
	background: $gray-800 url('/assets/images/bg/bg_authentication.jpg') 50% 50% no-repeat;
	background-size: cover;
 
	#content
	{
		width: $content-authentication-w;
		padding: $content-authentication-padding;
		background: $content-block-bg;
		box-shadow: $content-block-shadow;
		border-radius: $border-radius;
	}

	.app-logo
	{
		margin: 0 0 $spacing-md;
		text-align: center;
	}
}