/* ========================================================================== */
/* BUTTONS CUSTOM */
/* Buttons styles */
/* ========================================================================== */

/* Buttons custom
 * ========================================================================== */

%icon-link 
{
    @extend .btn-reset;
    @include text-bold-italic;
    @include link-underline;
    color: $link-color;

    &:hover,
	&:focus 
	{
        color: $link-hover-color;
    }

	&[disabled] 
	{
		cursor: not-allowed;
		opacity: $btn-disabled-opacity;
    }
}

.icon-link {
    @extend %icon-link;
    padding: 0 0 0 $spacing;
    position: relative;

	i
	{
        @include position-absolute(null, null, null, 0);
        @include pos-center-y;
    }
}

.arrow-link
{
	@extend %icon-link;
	
	&-gray
	{
		color: #666;
	}

	&:before 
	{
        @extend %fa-icon;
        @extend .fal;
        @include text-semi-bold;
        margin-right: 5px;
        content: fa-content($fa-var-angle-right);
    }
}

.btn-rounded
{
	@include btn-rounded;
}